import React, { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import {
  Box,
  Heading,
  HStack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import useEagerConnect from '../../hooks/useEagerConnect';
import TokenList from '../../utils/tokenlist';
import BigNumber from 'bignumber.js';
import TransactionConfirmationModal from '../../components/TransactionConfirmationModal/TransactionConfirmationModal';
import VaultActions from './VaultActions';
import CurrencyInput from '../../components/CurrencyInput';
import useCoingeckoPrice from '../../hooks/useCoingeckoPrice';
import useHODLVaults from '../../hooks/useHODLVaults';
import { getHODLVaultsAddress } from '../../utils/address-helper';
import { useApprove } from '../../hooks/useApprove';
import { useAllowance } from '../../hooks/useAllowance';
import { DefaultChainId, FEE_PRICE } from '../../utils/consts';
import useCurrencyBalance from '../../hooks/useCurrencyBalance';
import { useHistory, useParams } from 'react-router-dom';
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';

const formatBalance = (token, balance) => {
  return token?.address
    ? balance
        .div(10 ** token?.decimals)
        .decimalPlaces(token?.decimals)
        .toFixed()
    : 0;
};

export default function HODLVaults() {
  useEagerConnect();

  const { account } = useWeb3React();
  const history = useHistory();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [attemptingTxn, setAttemptingTxn] = useState();
  const [txHash, setTxHash] = useState();
  const [errorText, setErrorText] = useState();
  const [pendingText, setPendingText] = useState();

  const { chainId } = useWeb3React();

  const { token: tokenSymbol } = useParams();
  const paramToken = TokenList[chainId || DefaultChainId].find(t => t.symbol === tokenSymbol);

  const [token, setToken] = useState(paramToken || TokenList[chainId || DefaultChainId]?.[1]);
  const [amount, setAmount] = useState('');
  const price = useCoingeckoPrice(token);
  const HODLVaultsAddress = getHODLVaultsAddress(chainId);

  const balance = useCurrencyBalance(token);

  const { onDeposit } = useHODLVaults();
  const { onApprove } = useApprove(token?.address, HODLVaultsAddress);

  const allowance = useAllowance(token?.address, HODLVaultsAddress);

  const color = useColorModeValue('gray.600', 'gray.300');

  const openRampModal = useCallback(() => {
    new RampInstantSDK({
      hostAppName: 'Carpe DAO',
      hostLogoUrl: 'https://i.imgur.com/3ggdgL6.png',
      swapAsset: 'ETH',
      userAddress: account,
    }).show();
  }, [account]);

  const hasBalance = formatBalance(token, balance) > 0;

  const handleApprove = useCallback(async () => {
    try {
      setAttemptingTxn(true);
      setTxHash(null);
      setErrorText(null);
      setPendingText(`Approving ${token.symbol}`);
      onOpen();
      const tx = await onApprove();

      setTxHash(tx.transactionHash);
      setAttemptingTxn(false);
      toast({ title: 'Successfully approved!', status: 'success' });
    } catch (err) {
      console.error(err.message);
      setErrorText('Transaction rejected');
    }
  }, [onOpen, onApprove, token?.symbol, toast]);

  const handleDeposit = useCallback(
    async (token, amount, secondsLocked) => {
      try {
        setAttemptingTxn(true);
        setTxHash(null);
        setErrorText(null);
        setPendingText(
          `Locking ${new BigNumber(amount).div(10 ** token.decimals)} ${token.symbol}`
        );
        onOpen();

        const tx = await onDeposit(token.address, amount, secondsLocked);

        setTxHash(tx.transactionHash);
        setAttemptingTxn(false);
        toast({ title: 'Successfully locked', status: 'success' });
      } catch (err) {
        console.error(err.message);
        setErrorText('Transaction rejected');
      }
    },
    [onOpen, onDeposit, toast]
  );

  useEffect(() => {
    if (token?.symbol) {
      history.replace(`/hodl/${token.symbol}`);
    }
  }, [history, token.symbol]);

  return (
    <>
      <TransactionConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        errorText={errorText}
        pendingText={pendingText}
        content={() => <Text>Locking</Text>}
      />
      <VStack w='100%' h='100%' py={{ base: '16', md: '32' }}>
        <VStack w='100%' spacing='4' maxW='520px' px='6'>
          <Box textAlign='center'>
            <Heading fontSize={{ base: '2xl', md: '4xl' }}>HODL Vaults</Heading>
            <Text
              fontSize={{ base: 'sm', md: 'md' }}
              color={useColorModeValue('gray.500', 'gray.200')}
            >
              No more buy high and sell low. HODL 2 Earn CARPE
            </Text>
          </Box>
          <VStack
            w='100%'
            bg={useColorModeValue('gray.100', 'gray.800')}
            borderRadius='8'
            p='6'
            spacing='5'
          >
            <VStack w='100%' alignItems='flex-start'>
              <CurrencyInput
                label='Asset'
                currency={token}
                setCurrency={setToken}
                amount={amount}
                setAmount={setAmount}
                amountInUsd={amount * price}
                type='hodl'
              />
              <HStack w='100%' justifyContent='space-between'>
                {hasBalance ? (
                  <Text color={color}>
                    {formatBalance(token, balance)} {token?.symbol}
                  </Text>
                ) : (
                  <Text
                    color={color}
                    cursor='pointer'
                    onClick={openRampModal}
                    _hover={{ textDecoration: 'underline' }}
                  >
                    Buy ETH
                  </Text>
                )}
                <HStack color={color} spacing='1'>
                  <Text>Fees: </Text>
                  <Text>
                    {new BigNumber(amount || 0).multipliedBy(FEE_PRICE).toFixed()} {token?.symbol}
                  </Text>
                  <Text fontSize='sm' fontStyle='italic'>
                    ({FEE_PRICE}%)
                  </Text>
                </HStack>
              </HStack>
            </VStack>
            <VaultActions
              token={token}
              amount={amount}
              setAmount={setAmount}
              allowance={allowance}
              handleDeposit={handleDeposit}
              handleApprove={handleApprove}
            />
          </VStack>
        </VStack>
      </VStack>
    </>
  );
}
