export const getBalanceOf = async (HODLVaultsContract, userAddress) => {
  try {
    const userBalance = await HODLVaultsContract.methods.balanceOf(userAddress).call();
    return userBalance;
  } catch (err) {
    return -1;
  }
};

export const getTokenOfOwnerByIndex = async (HODLVaultsContract, userAddress, index) => {
  try {
    const tokenOfOwner = await HODLVaultsContract.methods
      .tokenOfOwnerByIndex(userAddress, index)
      .call();
    return tokenOfOwner;
  } catch (err) {
    return -1;
  }
};

export const getDeposit = async (HODLVaultsContract, index) => {
  try {
    const tokenOfOwner = await HODLVaultsContract.methods.deposits(index).call();
    return tokenOfOwner;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getUserDeposits = async (HODLVaultsContract, userAddress) => {
  const numDeposits = await getBalanceOf(HODLVaultsContract, userAddress);
  if (numDeposits !== -1) {
    const userDeposits = [];
    for (let i = 0; i < numDeposits; i++) {
      const index = await getTokenOfOwnerByIndex(HODLVaultsContract, userAddress, i);
      const deposit = await getDeposit(HODLVaultsContract, index);
      userDeposits.push({ ...deposit, index });
    }

    return userDeposits.sort((d1, d2) => d1.withdrawTimestamp - d2.withdrawTimestamp);
  }
};

export const transferDeposit = (HODLVaultsContract, userAddress, toAddress, index) => {
  return HODLVaultsContract.methods
    .safeTransferFrom(userAddress, toAddress, index)
    .send({ from: userAddress });
};
