import { ChainId } from "../config/constants/networks";

const ETHERSCAN_PREFIXES = {
  [ChainId.MAINNET]: "",
  [ChainId.KOVAN]: "kovan.",
  [ChainId.RINKEBY]: "rinkeby.",
};

export const ExplorerDataType = {
  TRANSACTION: "transaction",
  TOKEN: "token",
  ADDRESS: "address",
  BLOCK: "block",
};

export function getExplorerLink(chainId, type, data) {
  const prefix =
    chainId === ChainId.BSC
      ? "https://bscscan.com"
      : `https://${ETHERSCAN_PREFIXES[chainId] ?? ""}etherscan.io`;

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${prefix}/tx/${data}`;

    case ExplorerDataType.TOKEN:
      return `${prefix}/token/${data}`;

    case ExplorerDataType.BLOCK:
      return `${prefix}/block/${data}`;

    case ExplorerDataType.ADDRESS:
      return `${prefix}/address/${data}`;
    default:
      return `${prefix}`;
  }
}
