import React from 'react';
import { useWeb3React } from '@web3-react/core';
import {
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Text,
  Center,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi';
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink';
import LinkWrapper from '../../components/LinkWrapper';
import LoadingCarpe from '../LoadingCarpe';

export function TransactionPendingContent({ pendingText, ...props }) {
  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalBody pt='24' pb='8'>
        <VStack w='100%' spacing='12'>
          <Center boxSize='24'>
            <LoadingCarpe />
          </Center>

          <VStack spacing='0'>
            <Text fontSize='2xl'>Waiting for confirmation</Text>

            <VStack>
              <Text fontSize='lg' fontWeight='600'>
                {pendingText}
              </Text>

              <Text fontSize='sm' color='gray.400'>
                Please confirm this transaction in your wallet
              </Text>
            </VStack>
          </VStack>
        </VStack>
      </ModalBody>
    </ModalContent>
  );
}

export function TransactionSubmittedContent({
  onClose,
  chainId,
  hash,
  ...props
  // currencyToAdd,
}) {
  //const { library } = useActiveWeb3React()
  // const { addToken, success } = useAddTokenToMetamask(currencyToAdd)

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalBody pt='24' pb='8'>
        <VStack w='100%' spacing='12'>
          <Center boxSize='24'>
            <Icon boxSize='24' as={FiCheckCircle} color='brand.500' />
          </Center>
          <VStack spacing='0'>
            <Text fontSize='2xl'>Transaction Submitted</Text>
            <LinkWrapper href={getExplorerLink(chainId, ExplorerDataType.TRANSACTION, hash)}>
              <Text color='brand.500' _hover={{ color: 'brand.300' }} textDecoration='underline'>
                View on Explorer
              </Text>
            </LinkWrapper>
          </VStack>
          {/* TODO: Currency to add */}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button w='100%' variant='primary' onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

export function TransactionErrorContent({ errorText, onClose, ...props }) {
  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader>Error</ModalHeader>
      <ModalBody>
        <VStack w='100%' spacing='12'>
          <Center boxSize='24'>
            <Icon boxSize='24' as={FiAlertCircle} color='red.500' />
          </Center>
          <Text fontSize='2xl'>{errorText}</Text>
        </VStack>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onClose} w='100%'>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

export default function TransactionConfirmationModal({
  isOpen,
  onClose,
  attemptingTxn,
  hash,
  errorText,
  pendingText,
  content,
  isCentered = true,
}) {
  const { chainId } = useWeb3React();

  if (!chainId) return null;

  return (
    <Modal isOpen={isOpen} isCentered={isCentered} onClose={onClose}>
      <ModalOverlay />
      {errorText ? (
        <TransactionErrorContent errorText={errorText} onClose={onClose} />
      ) : attemptingTxn ? (
        <TransactionPendingContent pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent chainId={chainId} hash={hash} onClose={onClose} />
      ) : (
        content()
      )}
    </Modal>
  );
}
