import { InjectedConnector } from '@web3-react/injected-connector';
import { NetworkConnector } from '@web3-react/network-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { Web3Provider } from '@ethersproject/providers';
import { ChainId } from '../config/constants/networks';

export const RPC = {
  [ChainId.MAINNET]: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  [ChainId.RINKEBY]: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  [ChainId.ROPSTEN]: 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
};

export const network = new NetworkConnector({
  urls: RPC,
  defaultChainId: ChainId.MAINNET,
});

export const injected = new InjectedConnector({
  urls: RPC,
  supportedChainIds: [ChainId.MAINNET, ChainId.RINKEBY, ChainId.ROPSTEN],
});

let networkLibrary;
export function getNetworkLibrary() {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider));
}

export const getLibrary = provider => {
  return provider;
};

export const walletconnect = new WalletConnectConnector({
  rpc: {
    [ChainId.MAINNET]: RPC[ChainId.MAINNET],
    [ChainId.RINKEBY]: RPC[ChainId.RINKEBY],
  },
  bridge: 'https://uniswap.bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000,
});
