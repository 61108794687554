const contracts = {
  HODLVaults: {
    1: '0x962031929d53380CFe26Ba90e184EdB4d1fFC2a6',
    4: '0xDA44614889996CC5A427B7e4d2C01CA2039C5D11',
  },
  governanceDistribution: {
    1: '0xD89422F72A2E0Bb370aD2d2C5Ab364752daAaF68',
    4: '0x07EFa5eDfA6A21D82c6eb9d22c8c996d684A27D6',
  },
};

export default contracts;
