import { useDisclosure, Icon, Image, HStack, Text } from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import CurrencySearchModal from './CurrencySearchModal/CurrencySearchModal';

export default function CurrencySelect({ currency, setCurrency, type, children, ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCurrencySelect = currency => {
    setCurrency(currency);
    onClose();
  };

  return (
    <>
      <HStack spacing='2' cursor='pointer' onClick={onOpen} {...rest}>
        {currency ? (
          <HStack spacing={{ base: '2', md: '6' }} _hover={{ color: 'brand.500' }} w='100%'>
            <HStack spacing='2' w='100%'>
              <Image src={currency.logoURI} h='5' borderRadius='full' />
              <Text lineHeight='1.2' fontSize={{ base: 'md', md: 'lg' }} fontWeight='semibold'>
                {currency.symbol}
              </Text>
            </HStack>
            <Icon as={FiChevronDown} />
          </HStack>
        ) : (
          <>
            <Text textTransform='uppercase'>Currency</Text>
            <Icon as={FiChevronDown} />
          </>
        )}
      </HStack>
      <CurrencySearchModal
        onCurrencySelect={handleCurrencySelect}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        type={type}
      />
    </>
  );
}
