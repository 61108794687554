import { useClipboard } from '@chakra-ui/hooks';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { ModalBody, ModalCloseButton, ModalContent, ModalHeader } from '@chakra-ui/modal';
import { useWeb3React } from '@web3-react/core';
import React from 'react';
import { FiCopy } from 'react-icons/fi';
import Jazzicon from '../Jazzicon';
import { Icon, useColorModeValue } from '@chakra-ui/react';
import useWeb3 from '../../hooks/useWeb3';
import useCurrencyBalance from '../../hooks/useCurrencyBalance';

export default function AccountContent({ onClose, ...props }) {
  const web3 = useWeb3();
  const { account, deactivate } = useWeb3React();
  const { onCopy, hasCopied } = useClipboard(account, 500);

  const balance = useCurrencyBalance();
  const carpeBalance = web3.utils.fromWei(balance.toFixed());
  const shortAddress =
    account && `${account.substr(0, 10)}...${account.substr(account.length - 4, account.length)}`;

  return (
    <ModalContent bg={useColorModeValue('gray.50', 'gray.800')} {...props}>
      <ModalCloseButton />
      <ModalHeader>Your Wallet</ModalHeader>
      <ModalBody>
        <VStack w='100%' spacing='3'>
          <VStack w='100%' alignItems='flex-start' spacing='1'>
            <HStack spacing='2'>
              <Text color={useColorModeValue('gray.500', 'gray.400')}>Balance:</Text>
              <Text>{carpeBalance} CARPE</Text>
            </HStack>
            <VStack
              w='100%'
              spacing='3'
              alignItems='start'
              p='3'
              borderRadius='md'
              bg={useColorModeValue('gray.100', 'gray.700')}
            >
              <HStack px='2' w='100%' justifyContent='space-between' fontSize='xl'>
                <HStack textTransform='initial' spacing='3'>
                  <Jazzicon address={account} diameter={20} />
                  <Text>{hasCopied ? 'Address copied' : shortAddress}</Text>
                </HStack>
                <Icon
                  as={FiCopy}
                  onClick={onCopy}
                  fontSize='xl'
                  cursor='pointer'
                  _hover={{
                    color: useColorModeValue('gray.700', 'gray.200'),
                  }}
                />
              </HStack>
            </VStack>
          </VStack>
          <Text
            alignSelf='flex-end'
            as='button'
            color='brand.500'
            onClick={() => {
              deactivate();
              onClose();
            }}
          >
            Disconnect
          </Text>
        </VStack>
      </ModalBody>
    </ModalContent>
  );
}
