import React from 'react';
import { Button, HStack, Icon, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { NetworkLabel } from '../../config/constants/networks';
import { MdError } from 'react-icons/md';

export default function WrongNetwork({ supportedNetworks }) {
  const networksString = supportedNetworks.reduce(
    (acc, network, idx) =>
      (acc += `${NetworkLabel[network]}${idx === supportedNetworks.length - 1 ? '.' : ', '}`),
    ''
  );

  return (
    <Tooltip label={`Only available on ${networksString}`}>
      <Button
        bg={useColorModeValue('gray.100', 'gray.800')}
        w='100%'
        _hover={{ bg: useColorModeValue('gray.200', 'gray.700') }}
      >
        <HStack>
          <Icon as={MdError} fontSize='lg' color='red.400' />
          <Text>Wrong Network</Text>
        </HStack>
      </Button>
    </Tooltip>
  );
}
