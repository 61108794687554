import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { approve } from '../utils/call-helper';
import { useERC20, useHODLVaults } from './useContract';

// Approve ERC20
export const useApprove = (tokenAddress, targetAddress) => {
  const { account } = useWeb3React();
  const erc20Contract = useERC20(tokenAddress);

  const handleApprove = useCallback(() => {
    return approve(erc20Contract, targetAddress, account);
  }, [account, erc20Contract, targetAddress]);

  return { onApprove: handleApprove };
};

// Approve HODL vaults
export const useHODLVaultsApprove = tokenAddress => {
  const { account } = useWeb3React();
  const HODLVaultsContract = useHODLVaults();

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(tokenAddress, HODLVaultsContract.options.address, account);
      return tx;
    } catch (e) {
      return false;
    }
  }, [account, tokenAddress, HODLVaultsContract]);

  return { onApprove: handleApprove };
};
