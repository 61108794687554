import addresses from '../config/constants/contracts';
import tokens from '../config/constants/tokens';
import { DefaultChainId } from './consts';

export const getAddress = (address, chainId) => {
  return address[chainId] ? address[chainId] : address[DefaultChainId];
};

export const getCarpeAddress = chainId => {
  return getAddress(tokens.carpe.address, chainId);
};

export const getHODLVaultsAddress = chainId => {
  return getAddress(addresses.HODLVaults, chainId);
};

export const getGovernanceDistributionAddress = chainId => {
  return getAddress(addresses.governanceDistribution, chainId);
};

const ethAddressRegExp = new RegExp(/^0x[a-fA-F0-9]{40}$/);

export const isValidETHAddress = address => {
  return ethAddressRegExp.test(address);
};
