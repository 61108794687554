import { HStack, Text } from '@chakra-ui/layout';
import { useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import Jazzicon from '../Jazzicon';

export default function Account(props) {
  const { account } = useWeb3React();

  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const hoverBg = useColorModeValue('gray.200', 'gray.700');

  const shortAddress =
    account && `${account.substr(0, 6)}...${account.substr(account.length - 4, account.length)}`;

  const diameter = useBreakpointValue({ base: 28, md: 24 });

  if (!account) return <></>;

  return (
    <HStack
      justifyContent='space-between'
      py='2'
      px='4'
      cursor='pointer'
      borderRadius='lg'
      bg={bgColor}
      _hover={{
        bg: hoverBg,
      }}
    >
      <Text fontWeight='medium' mr='3'>
        {shortAddress}
      </Text>
      <Jazzicon address={account} diameter={diameter} />
    </HStack>
  );
}
