import { useEffect, useState, useRef } from 'react';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';

import { ChainId } from '../config/constants/networks';
import { RPC } from '../utils/connectors';
import { DefaultChainId } from '../utils/consts';

const getWeb3NoAccount = chainId => {
  const rpcUrl = RPC[chainId || ChainId.MAINNET];
  const httpProvider = new Web3.providers.HttpProvider(rpcUrl, { timeout: 10000 });
  const web3NoAccount = new Web3(httpProvider);

  return web3NoAccount;
};

/**
 * Provides a web3 instance using the provider provided by useWallet
 * with a fallback of an httpProver
 * Recreate web3 instance only if the provider change
 */
const useWeb3 = chainId => {
  const { library } = useWeb3React();
  const refEth = useRef(library);
  const [web3, setWeb3] = useState(
    library ? new Web3(library) : getWeb3NoAccount(chainId || DefaultChainId)
  );

  useEffect(() => {
    if (library !== refEth.current) {
      setWeb3(library ? new Web3(library) : getWeb3NoAccount(chainId || DefaultChainId));
      refEth.current = library;
    }
  }, [library, chainId]);

  return web3;
};

export default useWeb3;
