import { useMemo } from 'react';
import useWeb3 from './useWeb3';
import {
  getHODLVaultsContract,
  getGovernanceDistributionContract,
  getErc20Contract,
  getErc721Contract,
  getWETHContract,
} from '../utils/contract-helper';
import { getCarpeAddress } from '../utils/address-helper';
import { useWeb3React } from '@web3-react/core';
import { DefaultChainId } from '../utils/consts';
import tokens from '../config/constants/tokens';

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = address => {
  const web3 = useWeb3();
  return useMemo(() => (address ? getErc20Contract(address, web3) : null), [address, web3]);
};

export function useWETHContract() {
  const web3 = useWeb3();
  const { chainId } = useWeb3React();

  return useMemo(
    () => getWETHContract(tokens.weth.address[chainId || DefaultChainId], web3),
    [web3, chainId]
  );
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = address => {
  const web3 = useWeb3();
  return useMemo(() => getErc721Contract(address, web3), [address, web3]);
};

export const useHODLVaults = () => {
  const web3 = useWeb3();
  const { chainId } = useWeb3React();
  return useMemo(() => getHODLVaultsContract(web3, chainId), [web3, chainId]);
};

export const useGovernanceDistribution = () => {
  const web3 = useWeb3();
  const { chainId } = useWeb3React();
  return useMemo(() => getGovernanceDistributionContract(web3, chainId), [web3, chainId]);
};

export const useCarpe = () => {
  const web3 = useWeb3();
  const { chainId } = useWeb3React();
  const carpeAddress = getCarpeAddress(chainId);
  return useMemo(() => getErc20Contract(carpeAddress, web3), [carpeAddress, web3]);
};
