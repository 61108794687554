const tokens = {
  carpe: {
    symbol: 'CARPE',
    address: {
      1: '0xb321cAbf2f4Dfb440B3BeFd3331A86b1b85c041F',
      4: '0xE591Df49D3eaD765c606A2458FE975627Ebcd9C8',
    },
    decimals: 18,
  },
  weth: {
    symbol: 'WETH',
    address: {
      1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      4: '0xc778417e063141139fce010982780140aa0cd5ab',
    },
    decimals: 18,
  },
};

export default tokens;
