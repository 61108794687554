import {
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  ModalBody,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { CurrencyBalance } from './CurrencySearch';

export default function CurrencyList({
  currencies = [],
  selectedCurrency,
  otherCurrency,
  onCurrencySelect,
}) {
  const hoverBg = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.600', 'gray.200');
  const subTextColor = useColorModeValue('gray.500', 'gray.300');
  const [searchQuery, setSearchQuery] = useState('');

  const filteredCurrencies = currencies.filter(
    currency =>
      currency.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      currency.symbol.toLowerCase().includes(searchQuery.toLocaleLowerCase())
  );

  return (
    <VStack w='100%' maxH='400px' borderColor='inherit' overflowY='auto' spacing='0'>
      <ModalBody w='100%' mb='2'>
        <InputGroup>
          <InputLeftElement>
            <Icon as={FiSearch} />
          </InputLeftElement>
          <Input
            variant='filled'
            placeholder='Search an asset'
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </InputGroup>
      </ModalBody>
      {filteredCurrencies.map(_currency => (
        <HStack
          px='6'
          py='2'
          w='100%'
          _hover={{ bg: hoverBg }}
          cursor='pointer'
          key={_currency.symbol}
          borderColor='inherit'
          onClick={() => onCurrencySelect(_currency)}
        >
          <HStack w='100%' justifyContent='space-between'>
            <HStack spacing='3'>
              <Image h='6' src={_currency.logoURI} borderRadius='full' />
              <VStack spacing='-2' alignItems='start' fontWeight='medium'>
                <Text fontSize='lg' fontWeight='semibold'>
                  {_currency.symbol}
                </Text>
                <Text fontSize='sm' color={subTextColor}>
                  {_currency.name}
                </Text>
              </VStack>
            </HStack>

            <CurrencyBalance currency={_currency} color={textColor} />
          </HStack>
        </HStack>
      ))}
    </VStack>
  );
}
