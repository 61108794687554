import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import useGovernanceClaim from '../../hooks/useGovernanceClaim';
import { BIG_ZERO } from '../../utils/big-number';
import BigNumber from 'bignumber.js';
import { HStack, Text } from '@chakra-ui/layout';
import { useColorModeValue } from '@chakra-ui/system';
import { Button, Icon, Image, Stack, useDisclosure, useToast, VStack } from '@chakra-ui/react';
import TransferModalDeposit from './TransferDepositModal';
import { API_COINGECKO } from '../../utils/swap';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import useHODLVaults from '../../hooks/useHODLVaults';
import { CarpeLogo, FOREVER_TIMESTAMP } from '../../utils/consts';
import IncreaseDeposit from './IncreaseDeposit';
import { FiSend } from 'react-icons/fi';
import tokens from '../../config/constants/tokens';
import TransactionConfirmationModal from '../../components/TransactionConfirmationModal';
import { transferDeposit } from '../../utils/HODLVaults-utils';
import { useHODLVaults as useHODLVaultsContract } from '../../hooks/useContract';
import { useWeb3React } from '@web3-react/core';

export default function DepositInfo({
  index,
  amount,
  token: tokenAddress,
  depositToken,
  depositTimestamp,
  withdrawTimestamp,
  fetchDepositData,
}) {
  const { checkClaim, calculateCarpeReward } = useGovernanceClaim();
  const { checkOwnership } = useHODLVaults();

  const [canClaim, setCanClaim] = useState(false);
  const [carpeReward, setCarpeReward] = useState(BIG_ZERO);
  const [isOwner, setIsOwner] = useState(false);
  const canUnlock = moment.unix(withdrawTimestamp).isSameOrBefore(moment());

  const [price, setPrice] = useState({ btc: 0, usd: 0 });
  const [showBTC, setShowBTC] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [attemptingTxn, setAttemptingTxn] = useState();
  const [txHash, setTxHash] = useState();
  const [errorText, setErrorText] = useState();
  const [pendingText, setPendingText] = useState();
  const [contentText, setContentText] = useState();
  const { onWithdraw, onIncreaseDeposit } = useHODLVaults();
  const { onClaim } = useGovernanceClaim();
  const toast = useToast();

  const { account } = useWeb3React();

  const HODLVaultsContract = useHODLVaultsContract();

  const fetchPrice = useCallback(async () => {
    if (depositToken?.coingeckoId) {
      const res = await fetch(
        `${API_COINGECKO}/simple/price?ids=${depositToken.coingeckoId}&vs_currencies=usd,btc`
      );

      const data = await res.json();
      setPrice(data?.[depositToken.coingeckoId] || { btc: 0, usd: 0 });
    }
  }, [depositToken?.coingeckoId]);

  const getData = useCallback(async () => {
    const claimRes = await checkClaim(index);
    setCanClaim(claimRes);
    const rewardRes = await calculateCarpeReward(
      tokenAddress,
      amount,
      new BigNumber(withdrawTimestamp).minus(depositTimestamp).toFixed()
    );
    setCarpeReward(new BigNumber(rewardRes));
    const ownerRes = await checkOwnership(index);
    setIsOwner(ownerRes);
  }, [
    amount,
    calculateCarpeReward,
    checkClaim,
    checkOwnership,
    depositTimestamp,
    index,
    tokenAddress,
    withdrawTimestamp,
  ]);

  const handleIncreaseDeposit = useCallback(
    async (depositIdx, amount) => {
      try {
        setAttemptingTxn(true);
        setTxHash(null);
        setErrorText(null);
        setContentText('Locking');
        setPendingText(
          `Locking ${new BigNumber(amount).div(10 ** depositToken?.decimals)} ${
            depositToken?.symbol
          }`
        );
        onOpen();

        const tx = await onIncreaseDeposit(depositIdx, amount);

        setTxHash(tx.transactionHash);
        setAttemptingTxn(false);
        fetchDepositData();
        getData();
        toast({ title: 'Successfully locked', status: 'success' });
      } catch (err) {
        console.error(err);
        setErrorText('Transaction rejected');
      }
    },
    [
      depositToken?.decimals,
      depositToken?.symbol,
      onOpen,
      onIncreaseDeposit,
      fetchDepositData,
      toast,
      getData,
    ]
  );

  const handleClaim = useCallback(
    async (depositIdx, amount) => {
      try {
        setAttemptingTxn(true);
        setTxHash(null);
        setErrorText(null);
        setContentText('Claiming');
        setPendingText(`Claiming ${amount.div(10 ** 18).toFixed(18)} ${tokens.carpe.symbol}`);
        onOpen();

        const tx = await onClaim(depositIdx);

        setTxHash(tx.transactionHash);
        setAttemptingTxn(false);
        fetchDepositData();
        getData();
        toast({ title: 'Successfully claimed', status: 'success' });
      } catch (err) {
        console.error(err);
        setErrorText('Transaction rejected');
      }
    },
    [fetchDepositData, onClaim, onOpen, toast, getData]
  );

  const handleTransfer = useCallback(
    async (toAddress, depositIdx) => {
      try {
        setAttemptingTxn(true);
        setTxHash(null);
        setErrorText(null);
        setContentText('Transfering');
        setPendingText(`Transfering your deposit`);
        onOpen();

        const tx = await transferDeposit(HODLVaultsContract, account, toAddress, depositIdx);

        setTxHash(tx.transactionHash);
        setAttemptingTxn(false);
        fetchDepositData();
        getData();
        toast({ title: 'Successfully transfered', status: 'success' });
      } catch (err) {
        console.error(err);
        setErrorText('Transaction rejected');
      }
    },
    [account, HODLVaultsContract, fetchDepositData, onOpen, toast, getData]
  );

  const handleWithdraw = useCallback(
    async (depositIdx, amount, token) => {
      try {
        setAttemptingTxn(true);
        setTxHash(null);
        setErrorText(null);
        setContentText('Unlocking');
        setPendingText(
          `Unlocking ${new BigNumber(amount).div(10 ** token.decimals)} ${token.symbol}`
        );
        onOpen();

        const tx = await onWithdraw(depositIdx);

        setTxHash(tx.transactionHash);
        setAttemptingTxn(false);
        fetchDepositData();
        toast({ title: 'Successfully unlocked', status: 'success' });
      } catch (err) {
        console.error(err);
        setErrorText('Transaction rejected');
      }
    },
    [onOpen, onWithdraw, fetchDepositData, toast]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    fetchPrice();
  }, [fetchPrice]);

  const lockedAmount = new BigNumber(amount).div(10 ** depositToken?.decimals);

  return (
    <VStack w='100%'>
      <TransactionConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        errorText={errorText}
        pendingText={pendingText}
        content={() => <Text>{contentText}</Text>}
      />
      <VStack
        w='100%'
        px={{ base: '4', md: '8' }}
        py='4'
        bg={useColorModeValue('gray.100', 'gray.800')}
        borderRadius='lg'
        h='100%'
        spacing='5'
      >
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          alignItems={{ base: 'flex-start', sm: 'center' }}
          w='100%'
          justifyContent='space-between'
          spacing='0'
        >
          <Text color={useColorModeValue('gray.600', 'gray.200')}>Amount Locked</Text>
          <VStack alignItems={{ base: 'flex-start', sm: 'flex-end' }} spacing='0'>
            <Text lineHeight='1.2' fontSize={{ base: 'xl', md: '2xl' }} fontWeight='semibold'>
              {lockedAmount.toFixed()} {depositToken?.symbol}
            </Text>
            <HStack
              lineHeight='1.2'
              color={useColorModeValue('gray.600', 'gray.200')}
              spacing='1'
              fontSize={{ base: 'sm', md: 'md' }}
            >
              {showBTC ? (
                <Text>= {lockedAmount.times(price.btc).toPrecision(6)} BTC</Text>
              ) : (
                <Text>= ${lockedAmount.times(price.usd).toPrecision(8)}</Text>
              )}
              <Icon
                as={HiOutlineSwitchHorizontal}
                cursor='pointer'
                fontSize='sm'
                mb='2px !important'
                onClick={() => setShowBTC(prev => !prev)}
              />
            </HStack>
          </VStack>
        </Stack>
        <VStack w='100%' alignItems='flex-start'>
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            alignItems={{ base: 'flex-start', sm: 'center' }}
            w='100%'
            justifyContent='space-between'
            spacing='0'
          >
            <Text color={useColorModeValue('gray.600', 'gray.200')}>Locked Since</Text>
            <Text fontWeight='semibold' fontSize='lg'>
              {moment.unix(depositTimestamp).format('DD/MM/yyyy, HH:mm')}
            </Text>
          </Stack>
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            alignItems={{ base: 'flex-start', sm: 'center' }}
            w='100%'
            justifyContent='space-between'
            spacing='0'
          >
            <Text color={useColorModeValue('gray.600', 'gray.200')}>Locked Until</Text>
            <Text fontWeight='semibold' fontSize='lg'>
              {withdrawTimestamp === FOREVER_TIMESTAMP
                ? 'FOREVER'
                : moment.unix(withdrawTimestamp).format('DD/MM/yyyy, HH:mm')}
            </Text>
          </Stack>
        </VStack>

        {canUnlock ? (
          <Button
            w='100%'
            variant='primary'
            onClick={() => handleWithdraw(index, amount, depositToken)}
            size='sm'
          >
            Withdraw
          </Button>
        ) : (
          <IncreaseDeposit
            depositIdx={index}
            handleIncreaseDeposit={handleIncreaseDeposit}
            token={depositToken}
          />
        )}
        {isOwner && (
          <HStack w='100%'>
            {canClaim && carpeReward.isGreaterThan(0) && (
              <Button
                w='100%'
                onClick={() => handleClaim(index, carpeReward)}
                size='sm'
                leftIcon={<Image src={CarpeLogo} h='3' />}
              >
                Claim CARPE
              </Button>
            )}
            <HStack w='100%'>
              <TransferModalDeposit handleTransfer={handleTransfer} depositIdx={index} w='100%'>
                <Button size='sm' w='100%' leftIcon={<Icon as={FiSend} color='brand.500' />}>
                  Transfer NFT
                </Button>
              </TransferModalDeposit>
            </HStack>
          </HStack>
        )}
      </VStack>
    </VStack>
  );
}
