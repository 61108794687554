import { HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import useBgGradient from '../../hooks/useBgGradient';
import LinkWrapper from '../LinkWrapper';

export default function Menu(props) {
  const bg = useColorModeValue('gray.100', 'gray.800');

  return (
    <HStack spacing='0' borderRadius='lg' bg={bg} {...props}>
      <MenuItem title='Portfolio' to='/' borderLeftRadius='lg' />
      <MenuItem title='HODL' to='/hodl' />
      <MenuItem title='Swap' to='/swap' borderRightRadius='lg' />
    </HStack>
  );
}

function MenuItem({ title, to, href, ...rest }) {
  const location = useLocation();
  const isActive = to === '/' ? location.pathname === to : location.pathname.includes(to);

  const defaultColor = useColorModeValue('gray.800', 'white');
  const color = useColorModeValue('gray.500', 'gray.300');
  const bgGradient = useBgGradient({ opacityHex: '74', endingPercentage: '100%' });

  return (
    <LinkWrapper navTo={to} href={href} style={{ width: '100%', textDecoration: 'none' }}>
      <HStack
        cursor='pointer'
        w='100%'
        bgGradient={isActive ? bgGradient : null}
        color={isActive ? defaultColor : color}
        py='2'
        px='4'
        alignItems='center'
        _hover={{
          color: defaultColor,
        }}
        {...rest}
      >
        <HStack flex={1} spacing='3'>
          <Text w='100%' fontSize='lg' fontWeight='600'>
            {title}
          </Text>
        </HStack>
      </HStack>
    </LinkWrapper>
  );
}
