import React from 'react';
import { Modal, ModalOverlay } from '@chakra-ui/react';
import CurrencySearch from './CurrencySearch';
import TokenList from '../../utils/tokenlist';
import { useWeb3React } from '@web3-react/core';
import { DefaultChainId } from '../../utils/consts';

export default function CurrencySearchModal({
  isOpen,
  selectedCurrency,
  otherCurrency,
  onCurrencySelect,
  onClose,
  type,
}) {
  const { chainId } = useWeb3React();

  const currencies =
    type === 'hodl'
      ? TokenList[chainId || DefaultChainId].filter(t => t.canHodl)
      : type === 'swap'
      ? TokenList[chainId || DefaultChainId].filter(t => t.canSwap)
      : TokenList[chainId || DefaultChainId];

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <CurrencySearch
        onClose={onClose}
        currencies={currencies}
        selectedCurrency={selectedCurrency}
        otherCurrency={otherCurrency}
        onCurrencySelect={onCurrencySelect}
      />
    </Modal>
  );
}

// const CurrencyModalView = {
//   SEARCH: 'search',
//   IMPORT_TOKEN: 'import-token',
//   IMPORT_LIST: 'import-list',
//   MANAGE: 'manage',
// };
// const [modalView, setModalView] = useState(CurrencyModalView.SEARCH);
// {modalView === CurrencyModalView.SEARCH ? (
//   ) : modalView === CurrencyModalView.IMPORT_TOKEN ? (
//     <ImportToken />
//   ) : modalView === CurrencyModalView.IMPORT_LIST ? (
//     <ImportList />
//   ) : modalView === CurrencyModalView.MANAGE ? (
//     <Manage />
//   ) : (
//     ''
//   )}
