import erc20Abi from '../abis/erc20.json';
import erc721Abi from '../abis/erc721.json';
import HODLVaultsAbi from '../abis/HODLVaults.json';
import wethAbi from '../abis/weth.json';
import governanceDistributionAbi from '../abis/governanceDistribution.json';

import { getHODLVaultsAddress, getGovernanceDistributionAddress } from './address-helper';

const getContract = (abi, address, web3) => {
  const _web3 = web3;
  return new _web3.eth.Contract(abi, address);
};

export const getErc20Contract = (address, web3) => {
  return getContract(erc20Abi, address, web3);
};

export const getErc721Contract = (address, web3) => {
  return getContract(erc721Abi, address, web3);
};

export const getWETHContract = (address, web3) => {
  return getContract(wethAbi, address, web3);
};

export const getHODLVaultsContract = (web3, chainId) => {
  return getContract(HODLVaultsAbi, getHODLVaultsAddress(chainId), web3);
};

export const getGovernanceDistributionContract = (web3, chainId) => {
  return getContract(governanceDistributionAbi, getGovernanceDistributionAddress(chainId), web3);
};
