import Bsc from "../../assets/networks/bsc-network.jpg";
import Kovan from "../../assets/networks/kovan-network.jpg";
import Mainnet from "../../assets/networks/mainnet-network.jpg";

export const ChainId = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  BSC: 56,
};

export const NetworkIcon = {
  [ChainId.MAINNET]: Mainnet,
  [ChainId.RINKEBY]: Kovan,
  [ChainId.ROPSTEN]: Kovan,
  [ChainId.BSC]: Bsc,
};

export const NetworkLabel = {
  [ChainId.MAINNET]: "Ethereum",
  [ChainId.RINKEBY]: "Rinkeby",
  [ChainId.ROPSTEN]: "Ropsten",
  [ChainId.BSC]: "BSC",
};

export const NetworkParams = {
  [ChainId.MAINNET]: {
    chainId: `0x1`,
    chainName: "Ethereum",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.infura.io/v3"],
    blockExplorerUrls: ["https://etherscan.com"],
  },
  [ChainId.BSC]: {
    chainId: `0x38`,
    chainName: "Binance Smart Chain",
    nativeCurrency: {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [ChainId.ROPSTEN]: {
    chainId: `0x3`,
    chainName: "Ropsten Test Network",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://ropsten.etherscan.io"],
  },
  [ChainId.RINKEBY]: {
    chainId: `0x4`,
    chainName: "Rinkeby Test Network",
    nativeCurrency: {
      name: "Rinkeby ETH",
      symbol: "RETH",
      decimals: 18,
    },
    rpcUrls: ["https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://rinkeby.etherscan.io"],
  },
};
