import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';

import { useWETHContract } from './useContract';
import useCurrencyBalance from './useCurrencyBalance';
import BigNumber from 'bignumber.js';

export const WrapType = {
  NOT_APPLICABLE: 'not-applicable',
  WRAP: 'wrap',
  UNWRAP: 'unwrap',
};

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE };
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(inputCurrency, outputCurrency, typedValue) {
  const { chainId, account } = useWeb3React();
  const wethContract = useWETHContract();
  const balance = useCurrencyBalance(account ?? undefined, inputCurrency);

  return useMemo(() => {
    if (!wethContract || !chainId || !inputCurrency || !outputCurrency) return NOT_APPLICABLE;

    const inputAmount = new BigNumber(typedValue);

    const hasInputAmount = Boolean(inputAmount.isGreaterThan(0));
    const sufficientBalance = inputAmount && balance && !balance.isLessThan(inputAmount);

    if (
      inputCurrency.isNative &&
      outputCurrency.address?.toLowerCase() === wethContract.options.address?.toLowerCase()
    ) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  const txReceipt = await wethContract.methods.deposit().send({
                    value: inputAmount.times(10 ** 18).toFixed(),
                    from: account,
                  });

                  return txReceipt;
                  // summary: `Wrap ${inputAmount.toSignificant(6)} ETH to WETH`,
                } catch (error) {
                  console.error('Could not deposit', error);
                }
              }
            : undefined,
        inputError: sufficientBalance
          ? undefined
          : hasInputAmount
          ? 'Insufficient ETH balance'
          : 'Enter ETH amount',
      };
    } else if (
      inputCurrency.address?.toLowerCase() === wethContract.options.address?.toLowerCase() &&
      outputCurrency.isNative
    ) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  const txReceipt = await wethContract.methods
                    .withdraw(inputAmount.times(10 ** 18).toFixed())
                    .send({ from: account });

                  return txReceipt;
                  // summary: `Unwrap ${inputAmount.toSignificant(6)} WETH to ETH`,
                } catch (error) {
                  console.error('Could not withdraw', error);
                }
              }
            : undefined,
        inputError: sufficientBalance
          ? undefined
          : hasInputAmount
          ? 'Insufficient WETH balance'
          : 'Enter WETH amount',
      };
    } else {
      return NOT_APPLICABLE;
    }
  }, [wethContract, account, chainId, inputCurrency, outputCurrency, typedValue, balance]);
}
