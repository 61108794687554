import React, { useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

export function ConfirmDialog({
  children,
  onConfirm,
  confirmText = 'Delete',
  cancelText = 'Cancel',
  colorScheme = 'red',
  description = `Are you sure? You can't undo this action afterwards.`,
  title = 'Delete',
  isDisabled = false,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const onClick = async () => {
    try {
      setIsLoading(true);
      await onConfirm();
    } catch (err) {
      toast({ title: err.message, status: 'error' });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <>
      <Box onClick={() => (isDisabled ? onClick() : setIsOpen(true))} {...rest}>
        {children}
      </Box>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent bg={useColorModeValue('gray.100', 'gray.800')}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{description}</AlertDialogBody>

            <AlertDialogFooter>
              <Button px='8' variant='ghost' ref={cancelRef} onClick={onClose}>
                {cancelText}
              </Button>
              <Button
                px='8'
                isLoading={isLoading}
                colorScheme={colorScheme}
                onClick={() => onClick()}
                ml={3}
              >
                {confirmText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
