import React from 'react';
import moment from 'moment';
import useCountdown from '../../hooks/useCountdown';
import { usePalette } from 'react-palette';
import {
  AspectRatio,
  Avatar,
  Box,
  HStack,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import { FiArrowRight, FiLock, FiUnlock } from 'react-icons/fi';
import { BsQuestion } from 'react-icons/bs';
import { FOREVER_TIMESTAMP } from '../../utils/consts';

const DepositCard = ({ index, amount, token, tokenAddress, withdrawTimestamp, ...props }) => {
  const { timeLeft, leadingZero } = useCountdown(moment.unix(withdrawTimestamp).toDate());
  const { isOpen: isHovering, onOpen: onHoverIn, onClose: onHoverOut } = useDisclosure();

  const { data, loading, error } = usePalette(token?.logoURI);
  const canUnlock = moment.unix(withdrawTimestamp).isSameOrBefore(moment());

  const defaultBgGradient = useColorModeValue(
    'linear(to-b, gray.400, gray.200)',
    'linear(to-b, gray.800, gray.600)'
  );
  const bgGradient = `linear(to-b, ${useColorModeValue(
    data.vibrant + 'dd',
    data.lightVibrant + '22'
  )}, ${useColorModeValue(data.darkVibrant + '22', data.vibrant + '55')})`;

  return (
    <AspectRatio ratio={2 / 3} {...props}>
      <Box
        bgGradient={error || loading ? defaultBgGradient : bgGradient}
        w='100%'
        borderRadius='lg'
        position='relative'
        transition='all 0.3s'
        cursor='pointer'
        onMouseEnter={onHoverIn}
        onMouseLeave={onHoverOut}
      >
        <HStack
          w='100%'
          alignItems='flex-start'
          justifyContent='space-between'
          position='absolute'
          top={{ base: '2', md: '3' }}
          left='0'
          px={{ base: '2', md: '3' }}
        >
          <VStack alignItems='flex-start' spacing='0'>
            <Text lineHeight='1.2' fontSize={{ base: 'lg', md: '2xl' }} fontWeight='semibold'>
              {token?.symbol}
            </Text>
            <Text lineHeight='1.2' fontSize={{ base: 'sm', md: 'md' }}>
              {new BigNumber(amount).div(10 ** (token?.decimals || 18)).toFixed()}
            </Text>
          </VStack>
          <Text fontSize={{ base: 'md', md: 'xl' }}>#{index}</Text>
        </HStack>

        <Avatar
          icon={<BsQuestion />}
          src={token?.logoURI}
          transition='all 0.4s'
          fontSize={{ base: '3em', md: '5em' }}
          boxSize={{ base: isHovering ? '4.4rem' : '3.8rem', md: isHovering ? '6.4rem' : '6rem' }}
          boxShadow={
            isHovering ? '3px 8px 20px 0px rgba(0,0,0,0.6)' : '1px 5px 10px 0px rgba(0,0,0,0.4)'
          }
          borderRadius='full'
          position='absolute'
          top='0'
          bottom='0'
          right='0'
          left='0'
          m='auto'
        />

        <HStack
          w='100%'
          alignSelf='flex-start'
          position='absolute'
          bottom={{ base: '2', md: '3' }}
          px={{ base: '1', md: '3' }}
        >
          {canUnlock ? (
            <HStack w='100%' justifyContent='space-between'>
              <Icon as={FiUnlock} mb='2px' />
              <HStack
                fontSize={{ base: 'md', md: 'lg' }}
                fontWeight='medium'
                lineHeight='1.2'
                cursor='pointer'
                _hover={{ textDecoration: 'underline' }}
              >
                <Text>Unlock now</Text>
                <Icon as={FiArrowRight} mb='2px' />
              </HStack>
            </HStack>
          ) : (
            <HStack spacing={{ base: 1, md: 2 }}>
              <Icon
                fontSize={{ base: 'xs', md: 'lg' }}
                display={{ base: 'none', md: 'inline' }}
                as={FiLock}
                mb='2px'
              />
              {withdrawTimestamp === FOREVER_TIMESTAMP ? (
                <Text>LOCKED FOREVER</Text>
              ) : (
                <HStack
                  spacing='1'
                  fontSize={{ base: 'xs', md: 'lg' }}
                  fontWeight='medium'
                  lineHeight='1.2'
                >
                  {timeLeft.years > 0 && <Text>{timeLeft.years}y</Text>}
                  {timeLeft.days > 0 && <Text>{timeLeft.days}d</Text>}
                  {timeLeft.hours > 0 && <Text>{leadingZero(timeLeft.hours)}h</Text>}
                  {timeLeft.minutes > 0 && <Text>{leadingZero(timeLeft.minutes)}m</Text>}
                  {timeLeft.seconds > 0 && <Text>{leadingZero(timeLeft.seconds)}s</Text>}
                </HStack>
              )}
            </HStack>
          )}
        </HStack>
      </Box>
    </AspectRatio>
  );
};

export default DepositCard;
