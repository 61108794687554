import { useEffect, useState } from 'react';

export default function useCountdown(endDate) {
  const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });

  const leadingZero = num => (num < 10 ? '0' + num : num);

  const getTimeUntil = date => {
    const time = date - new Date();

    if (time < 0) {
      setTimeLeft({ minutes: 0, seconds: 0, hours: 0, days: 0 });
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / 1000 / 60 / 60) % 24);
      const days = Math.floor((time / 1000 / 60 / 60 / 24) % 365);
      const years = Math.floor(time / 1000 / 60 / 60 / 24 / 365);
      setTimeLeft({ seconds, minutes, hours, days, years });
    }
  };

  useEffect(() => {
    const id = setInterval(() => {
      getTimeUntil(endDate);
    }, 1000);

    return () => clearInterval(id);
  }, [endDate]);

  return { timeLeft, leadingZero };
}
