import React from 'react';
import {
  Box,
  Center,
  HStack,
  IconButton,
  Input,
  Select,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import './react-datepicker.css';
import moment from 'moment';

export default function DateTimePicker({
  selectedDate,
  onChange,
  popperPlacement = 'bottom-end',
  children,
}) {
  const withPortal = useBreakpointValue({ base: true, md: false });
  return (
    <Box>
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        renderCustomHeader={renderCustomHeader}
        minDate={new Date()}
        customInput={children}
        showTimeInput
        timeInputLabel=''
        customTimeInput={<CustomTimeInput />}
        showPopperArrow={false}
        popperPlacement={popperPlacement}
        filterDate={date => moment(date).isSameOrAfter(moment(), 'day')}
        withPortal={withPortal}
        shouldCloseOnSelect={false}
      />
    </Box>
  );
}

const renderCustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <HStack w='100%' justifyContent='space-between'>
      <IconButton
        variant='none'
        icon={<FiChevronLeft />}
        onClick={decreaseMonth}
        visibility={prevMonthButtonDisabled && 'hidden'}
      />
      <HStack>
        <Select
          variant='unstyled'
          className='rdp-month-select'
          icon={<></>}
          value={moment(date).format('MMMM')}
          onChange={e => changeMonth(months.indexOf(e.target.value))}
          p='0'
        >
          {months.map(month => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </Select>
        <Input
          type='number'
          variant='unstyled'
          value={moment(date).get('year')}
          onChange={e => {
            let error = isNaN(moment(date).set('year', parseInt(e.target.value)).toDate());
            if (!error) changeYear(e.target.value);
          }}
          w='40px'
          minWidth='unset'
        />
      </HStack>
      <IconButton
        variant='none'
        icon={<FiChevronRight />}
        onClick={increaseMonth}
        visibility={nextMonthButtonDisabled && 'hidden'}
      />
    </HStack>
  );
};

const CustomTimeInput = ({ value, onChange }) => {
  const [selectedHours, selectedMinutes] = value.split(':');
  const hoverBg = useColorModeValue('gray.300', 'gray.700');

  return (
    <VStack w='100%' spacing='0' py={{ base: '0', md: '2' }}>
      <Center
        w='100%'
        py='3'
        borderBottom='1px solid'
        borderColor={useColorModeValue('gray.300', 'whiteAlpha.300')}
      >
        <Text fontSize='lg' fontWeight='semibold'>
          Time
        </Text>
      </Center>
      <HStack w='100%' spacing='0' alignItems='flex-start'>
        <VStack
          flex={1}
          spacing='0'
          h='100%'
          maxH={{ base: '160px', md: '200px' }}
          overflowY='scroll'
        >
          {hours.map(h => {
            const isActive = h === selectedHours;
            return (
              <Center
                key={h}
                w='16'
                py='1'
                bg={isActive ? 'brand.500' : 'transparent'}
                fontWeight={isActive ? 'bold' : 'normal'}
                cursor='pointer'
                _hover={{
                  bg: isActive ? 'brand.600' : hoverBg,
                }}
                onClick={() => onChange(`${h}:${selectedMinutes}`)}
              >
                {h}
              </Center>
            );
          })}
        </VStack>
        <VStack
          flex={1}
          spacing='0'
          h='100%'
          maxH={{ base: '160px', md: '200px' }}
          overflowY='scroll'
        >
          {minutes.map(m => {
            const isActive = m === selectedMinutes;
            return (
              <Center
                key={m}
                w='16'
                py='1'
                bg={isActive ? 'brand.500' : 'transparent'}
                fontWeight={isActive ? 'bold' : 'normal'}
                cursor='pointer'
                _hover={{
                  bg: isActive ? 'brand.600' : hoverBg,
                }}
                onClick={() => onChange(`${selectedHours}:${m}`)}
              >
                {m}
              </Center>
            );
          })}
        </VStack>
      </HStack>
    </VStack>
  );
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const minutes = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
];

const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];
