import { Box, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { CarpeWhiteLogo } from '../../utils/consts';

const MotionBox = motion(Box);

export default function LoadingCarpe() {
  return (
    <MotionBox
      as='aside'
      animate={{
        rotate: [0, 0, 360, 360, 0],
        borderRadius: ['50%', '50%', '20%', '20%', '50%'],
      }}
      transition={{
        duration: 2,
        ease: 'easeInOut',
        times: [0, 0.2, 0.45, 0.7, 1],
        repeat: Infinity,
        repeatType: 'loop',
      }}
      padding='2.5'
      bgGradient='linear(to-l, brand.500, brand.600)'
      width='16'
      height='16'
      display='flex'
    >
      <Image w='100%' h='100%' src={CarpeWhiteLogo} />
    </MotionBox>
  );
}
