import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { getErc20Contract } from '../utils/contract-helper';
import { BIG_ZERO } from '../utils/big-number';
import useWeb3 from './useWeb3';
import useRefresh from './useRefresh';
import { getCarpeAddress } from '../utils/address-helper';

const useCurrencyBalance = currency => {
  const [balance, setBalance] = useState(BIG_ZERO);
  const { account, chainId } = useWeb3React();
  const web3 = useWeb3();
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        let res;
        if (currency?.isNative) {
          res = await web3.eth.getBalance(account);
        } else {
          const contract = getErc20Contract(currency?.address || getCarpeAddress(chainId), web3);
          res = await contract.methods.balanceOf(account).call();
        }
        setBalance(new BigNumber(res));
      } catch (err) {}
    };

    if (account) {
      fetchBalance();
    }
  }, [account, currency, web3, fastRefresh, chainId]);

  return balance;
};

export default useCurrencyBalance;
