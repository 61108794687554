import { Button, Heading, HStack, Image } from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { CarpeLogo } from '../../utils/consts';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { Account, WalletModal } from '../../components';
import Menu from '../../components/Menu';
import ExtraMenu from '../../components/ExtraMenu';
import { ChainId } from '../../config/constants/networks';
import WrongNetwork from '../../components/WrongNetwork/WrongNetwork';

export default function Header() {
  return (
    <HStack h='20' w='100%' px='5' py='2' justifyContent='space-between'>
      <HStack spacing='6'>
        <NavLink to='/'>
          <HStack spacing='2'>
            <Image src={CarpeLogo} h='7' />
            <Heading size='md' display={{ base: 'none', xl: 'inline' }}>
              Carpe DAO
            </Heading>
          </HStack>
        </NavLink>
        <Menu display={{ base: 'none', md: 'flex', xl: 'none' }} />
      </HStack>

      <HStack display={{ base: 'none', xl: 'flex' }} position='absolute' left='50%'>
        <Menu position='relative' left='-50%' />
      </HStack>

      <HStack justifyContent='space-between' px='6'>
        <Wallet supportedNetworks={[ChainId.MAINNET, ChainId.RINKEBY]} />
        <ExtraMenu />
      </HStack>
    </HStack>
  );
}

function Wallet({ supportedNetworks, ...props }) {
  const { account, chainId, error } = useWeb3React();

  if (error && error instanceof UnsupportedChainIdError) {
    return <WrongNetwork supportedNetworks={supportedNetworks} />;
  }

  return (
    <HStack {...props}>
      {account ? (
        !supportedNetworks.includes(chainId) ? (
          <WrongNetwork supportedNetworks={supportedNetworks} />
        ) : (
          <WalletModal w='100%'>
            <Account />
          </WalletModal>
        )
      ) : (
        <WalletModal>
          <Button size='sm' variant='primary'>
            Connect Wallet
          </Button>
        </WalletModal>
      )}
    </HStack>
  );
}
