import { ChainId } from '../config/constants/networks';
import tokens from '../config/constants/tokens';
import {
  AAVEImage,
  BTCImage,
  CarpeImage,
  COMPImage,
  CRVImage,
  DAIImage,
  ETHImage,
  MKRImage,
  UNIImage,
  USDCImage,
  USDTImage,
  WETHImage,
} from './consts';

const TokenList = {
  [ChainId.RINKEBY]: [
    {
      chainId: ChainId.RINKEBY,
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      logoURI: ETHImage,
      isNative: true,
      coingeckoId: 'ethereum',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      chainId: ChainId.RINKEBY,
      name: 'Bitcoin',
      symbol: 'WBTC',
      address: '0xEF5a298A035Bf61C0b1534c2d3b2866fABf62d4a',
      decimals: 8,
      logoURI: BTCImage,
      coingeckoId: 'bitcoin',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      chainId: ChainId.RINKEBY,
      name: 'Ethereum',
      symbol: 'WETH',
      address: '0xc778417e063141139fce010982780140aa0cd5ab',
      decimals: 18,
      logoURI: ETHImage,
      coingeckoId: 'ethereum',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      chainId: ChainId.RINKEBY,
      name: 'Carpe DAO',
      symbol: 'CARPE',
      address: tokens.carpe.address[ChainId.RINKEBY],
      decimals: 18,
      logoURI: CarpeImage,
      coingeckoId: 'carpe',
      givesGovernance: false,
      canSwap: false,
      canHodl: true,
    },
    {
      chainId: ChainId.RINKEBY,
      name: 'Tether USD',
      symbol: 'USDT',
      address: '0x9a98c89055Fcd3148AE674b53a01b6ad948ae843',
      decimals: 6,
      logoURI: USDTImage,
      coingeckoId: 'tether',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      chainId: ChainId.RINKEBY,
      address: '0xad02da7dad835d3ec6fe6512658b7f610a3a6d67',
      name: 'Aave',
      symbol: 'AAVE',
      decimals: 18,
      logoURI: AAVEImage,
      coingeckoId: 'aave',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      chainId: ChainId.RINKEBY,
      name: 'Compound',
      address: '0x7a0ce4b69ca9bbf695009889a94bbdaa82a1df3f',
      symbol: 'COMP',
      decimals: 18,
      logoURI: COMPImage,
      coingeckoId: 'compound',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      chainId: ChainId.RINKEBY,
      name: 'Curve DAO Token',
      address: '0x0d2ae9fa506be16bec3cf7a1e6dd692220438dad',
      symbol: 'CRV',
      decimals: 18,
      logoURI: CRVImage,
      coingeckoId: 'curve-dao-token',
      canSwap: true,
      canHodl: true,
    },
    {
      chainId: ChainId.RINKEBY,
      name: 'Maker',
      address: '0x2a955688b0dd2f97431377ac03e3dd315afb75b6',
      symbol: 'MKR',
      decimals: 18,
      logoURI: MKRImage,
      coingeckoId: 'maker',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      chainId: ChainId.RINKEBY,
      name: 'Uniswap',
      address: '0x62b9d74c6d5e41cb1dfd0557b33bfe5060953752',
      symbol: 'UNI',
      decimals: 18,
      logoURI: UNIImage,
      coingeckoId: 'uniswap',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
  ],
  [ChainId.MAINNET]: [
    {
      chainId: 1,
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      logoURI: ETHImage,
      isNative: true,
      coingeckoId: 'ethereum',
      givesGovernance: true,
      canSwap: true,
      canHodl: false,
    },
    {
      chainId: 1,
      address: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
      name: 'Aave',
      symbol: 'AAVE',
      decimals: 18,
      logoURI: AAVEImage,
      coingeckoId: 'aave',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      name: 'Compound',
      address: '0xc00e94Cb662C3520282E6f5717214004A7f26888',
      symbol: 'COMP',
      decimals: 18,
      chainId: 1,
      logoURI: COMPImage,
      coingeckoId: 'compound',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      name: 'Curve DAO Token',
      address: '0xD533a949740bb3306d119CC777fa900bA034cd52',
      symbol: 'CRV',
      decimals: 18,
      chainId: 1,
      logoURI: CRVImage,
      coingeckoId: 'curve-dao-token',
      canSwap: true,
      canHodl: true,
    },
    {
      name: 'Dai Stablecoin',
      address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
      symbol: 'DAI',
      decimals: 18,
      chainId: 1,
      logoURI: DAIImage,
      coingeckoId: 'dai',
      canSwap: true,
      canHodl: true,
    },
    {
      name: 'Maker',
      address: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
      symbol: 'MKR',
      decimals: 18,
      chainId: 1,
      logoURI: MKRImage,
      coingeckoId: 'maker',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      name: 'Uniswap',
      address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
      symbol: 'UNI',
      decimals: 18,
      chainId: 1,
      logoURI: UNIImage,
      coingeckoId: 'uniswap',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      name: 'USDCoin',
      address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      symbol: 'USDC',
      decimals: 6,
      chainId: 1,
      logoURI: USDCImage,
      coingeckoId: 'usd-coin',
      canSwap: true,
      canHodl: true,
    },
    {
      name: 'Tether USD',
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      symbol: 'USDT',
      decimals: 6,
      chainId: 1,
      logoURI: USDTImage,
      coingeckoId: 'tether',
      canSwap: true,
      canHodl: true,
    },
    {
      name: 'Wrapped BTC',
      address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
      symbol: 'WBTC',
      decimals: 8,
      chainId: 1,
      logoURI: BTCImage,
      coingeckoId: 'wrapped-bitcoin',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      name: 'Wrapped Ether',
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      symbol: 'WETH',
      decimals: 18,
      chainId: 1,
      logoURI: WETHImage,
      coingeckoId: 'ethereum',
      givesGovernance: true,
      canSwap: true,
      canHodl: true,
    },
    {
      name: 'Carpe DAO',
      address: tokens.carpe.address[ChainId.MAINNET],
      symbol: 'CARPE',
      decimals: 18,
      chainId: 1,
      logoURI: CarpeImage,
      coingeckoId: '',
      givesGovernance: false,
      canSwap: false,
      canHodl: true,
    },
  ],
};

export default TokenList;
