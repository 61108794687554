import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useHODLVaults as useHODLVaultsContract } from './useContract';

const useHODLVaults = () => {
  const { account } = useWeb3React();
  const HODLVaultsContract = useHODLVaultsContract();

  const handleDeposit = useCallback(
    (token, depositAmount, withdrawTimestamp, tokenURI = '') => {
      return HODLVaultsContract.methods
        .deposit(token, depositAmount, withdrawTimestamp, tokenURI)
        .send({ from: account });
    },
    [account, HODLVaultsContract.methods]
  );

  const handleIncreaseDeposit = useCallback(
    (depositIdx, depositAmount) => {
      return HODLVaultsContract.methods
        .increaseDeposit(depositIdx, depositAmount)
        .send({ from: account });
    },
    [account, HODLVaultsContract.methods]
  );

  const handleWithdraw = useCallback(
    depositIdx => {
      return HODLVaultsContract.methods.withdraw(depositIdx).send({ from: account });
    },
    [account, HODLVaultsContract.methods]
  );

  const checkOwnership = useCallback(
    async depositIdx => {
      try {
        const res = await HODLVaultsContract.methods.ownerOf(depositIdx).call();
        return res === account;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    [account, HODLVaultsContract.methods]
  );

  return {
    onDeposit: handleDeposit,
    onWithdraw: handleWithdraw,
    checkOwnership,
    onIncreaseDeposit: handleIncreaseDeposit,
  };
};

export default useHODLVaults;
