import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useGovernanceDistribution } from './useContract';

const useGovernanceClaim = () => {
  const { account } = useWeb3React();
  const governanceDistributor = useGovernanceDistribution();

  const handleClaim = useCallback(
    depositIdx => {
      return governanceDistributor.methods.claimTokens(depositIdx).send({ from: account });
    },
    [account, governanceDistributor.methods]
  );

  const checkClaim = useCallback(
    async depositIdx => {
      try {
        const canClaim = await governanceDistributor.methods
          .depositClaimedTimestamp(depositIdx)
          .call();

        return canClaim === '0';
      } catch (err) {
        return false;
      }
    },
    [governanceDistributor.methods]
  );

  const calculateCarpeReward = useCallback(
    async (token, amount, timeLocked) => {
      try {
        const rewards = await governanceDistributor.methods
          .calculateCarpeReward(token, amount, timeLocked)
          .call();
        return rewards;
      } catch (err) {
        return 0;
      }
    },
    [governanceDistributor.methods]
  );

  return { onClaim: handleClaim, calculateCarpeReward, checkClaim };
};

export default useGovernanceClaim;
