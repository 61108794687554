import React from "react";
import { Box, Link as ChakraLink } from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";

const LinkWrapper = React.forwardRef((props, ref) => {
  const { to, navTo, href, children, isDisabled, ...rest } = props;

  if (isDisabled) return children;

  if (to)
    return (
      <Link to={to} ref={ref} {...rest}>
        {children}
      </Link>
    );

  if (navTo)
    return (
      <NavLink to={navTo} innerRef={ref} {...rest}>
        {children}
      </NavLink>
    );

  if (href)
    return (
      <ChakraLink href={href} ref={ref} target="_blank" {...rest}>
        {children}
      </ChakraLink>
    );

  return <Box {...rest}>{children}</Box>;
});

export default LinkWrapper;
