import React from 'react';
import { Box, Flex, VStack } from '@chakra-ui/react';
import Header from './Header';
import useBgGradient from '../../hooks/useBgGradient';
import Menu from '../../components/Menu';

const MainLayout = props => {
  const bgGradient = useBgGradient();
  const { children } = props;

  return (
    <VStack h='100%' w='100%' bgGradient={bgGradient}>
      <Header />
      <Flex direction='column' h='100%' w='100%' overflowY='auto'>
        {children}
      </Flex>
      <Box py='2' display={{ base: 'flex', md: 'none' }}>
        <Menu />
      </Box>
    </VStack>
  );
};

export default MainLayout;
