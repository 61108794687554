import React from 'react';
import {
  HStack,
  Icon,
  Link as CLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { FiFile, FiGithub, FiMoon, FiSun, FiTwitter } from 'react-icons/fi';
import { FaTelegramPlane } from 'react-icons/fa';

export default function ExtraMenu() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Menu autoSelect={false} closeOnSelect={false}>
      <MenuButton
        fontSize='2xl'
        borderRadius='lg'
        boxSize='10'
        display='flex'
        alignItems='center'
        justifyContent='center'
        bg={useColorModeValue('gray.100', 'gray.800')}
        _hover={{
          bg: useColorModeValue('gray.200', 'gray.700'),
        }}
      >
        <Icon as={BiDotsVerticalRounded} />
      </MenuButton>
      <MenuList>
        <MenuItem
          as={CLink}
          href='https://twitter.com/0xCarpeDAO'
          _hover={{ textDecoration: 'none' }}
          target='_blank'
        >
          <HStack w='100%' justifyContent='space-between'>
            <Text>Twitter</Text>
            <Icon as={FiTwitter} />
          </HStack>
        </MenuItem>
        <MenuItem
          as={CLink}
          href='https://github.com/CarpeDAO'
          _hover={{ textDecoration: 'none' }}
          target='_blank'
        >
          <HStack w='100%' justifyContent='space-between'>
            <Text>Code</Text>
            <Icon as={FiGithub} />
          </HStack>
        </MenuItem>
        <MenuItem
          as={CLink}
          href='https://t.me/carpeDAO'
          _hover={{ textDecoration: 'none' }}
          target='_blank'
        >
          <HStack w='100%' justifyContent='space-between'>
            <Text>Telegram</Text>
            <Icon as={FaTelegramPlane} />
          </HStack>
        </MenuItem>
        <MenuItem as='a' href={process.env.PUBLIC_URL + '/whitepaper.pdf'} target='_blank'>
          <HStack w='100%' justifyContent='space-between'>
            <Text>Whitepaper</Text>
            <Icon as={FiFile} />
          </HStack>
        </MenuItem>
        <MenuItem onClick={toggleColorMode}>
          <HStack w='100%' justifyContent='space-between'>
            <Text>{colorMode === 'dark' ? 'Light' : 'Dark'} Mode</Text>
            <Icon as={colorMode === 'dark' ? FiSun : FiMoon} />
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
