import React from 'react';
import { SUPPORTED_WALLETS } from '../../config/constants/wallets';
import Option from './Option';
import { injected } from '../../utils/connectors';
import { HStack, VStack, Spinner, Button, Text, ModalBody } from '@chakra-ui/react';

export default function PendingView({ connector, error = false, setPendingError, tryActivation }) {
  const isMetamask = window?.ethereum?.isMetaMask;

  return (
    <ModalBody>
      <VStack w='100%' spacing='4'>
        {error ? (
          <HStack p='4' w='100%' border='1px solid' borderRadius='xl' borderColor='red.300'>
            <Text color='red.300'>Error connecting</Text>
            <Button
              size='sm'
              onClick={() => {
                setPendingError(false);
                connector && tryActivation(connector);
              }}
            >
              Try Again
            </Button>
          </HStack>
        ) : (
          <HStack p='4' w='100%' border='1px solid' borderRadius='xl' borderColor='brand.500'>
            <Spinner size='sm' speed='2s' color='brand.500' />
            <Text>Initializing...</Text>
          </HStack>
        )}
        {Object.keys(SUPPORTED_WALLETS).map(key => {
          const option = SUPPORTED_WALLETS[key];
          if (option.connector === connector) {
            if (option.connector === injected) {
              if (isMetamask && option.name !== 'MetaMask') {
                return null;
              }
              if (!isMetamask && option.name === 'MetaMask') {
                return null;
              }
            }
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                clickable={false}
                color={option.color}
                header={option.name}
                subheader={option.description}
                icon={option.iconURL}
              />
            );
          }
          return null;
        })}
      </VStack>
    </ModalBody>
  );
}
