import React, { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import useCurrencyBalance from '../../hooks/useCurrencyBalance';
import { ChainId } from '../../config/constants/networks';
import {
  Button,
  Divider,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import { WalletModal } from '../../components';
import WrongNetwork from '../../components/WrongNetwork/WrongNetwork';
import moment from 'moment';
import { ImInfinite } from 'react-icons/im';
import { CarpeLogo, FOREVER_TIMESTAMP_HEX } from '../../utils/consts';
import useGovernanceClaim from '../../hooks/useGovernanceClaim';
import { BIG_ZERO } from '../../utils/big-number';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { FiCalendar } from 'react-icons/fi';

export default function VaultActions({
  token,
  handleDeposit,
  handleApprove,
  allowance,
  amount,
  setAmount,
  ...props
}) {
  const { chainId, account } = useWeb3React();
  const [unlockDate, setUnlockDate] = useState(moment().add(1, 'year').toDate());
  const [isForever, setIsForever] = useState(false);

  const balance = useCurrencyBalance(token);

  const supportedNetworks = [ChainId.MAINNET, ChainId.RINKEBY];
  const isWrongNetwork = !supportedNetworks.includes(chainId);

  if (!account)
    return (
      <WalletModal w='100%'>
        <Button w='100%' variant='primary'>
          Connect wallet
        </Button>
      </WalletModal>
    );

  if (isWrongNetwork) return <WrongNetwork supportedNetworks={supportedNetworks} />;

  if (balance.div(10 ** token?.decimals).comparedTo(new BigNumber(amount)) === -1)
    return (
      <Wrapper
        token={token}
        amount={new BigNumber(amount)}
        setAmount={setAmount}
        unlockDate={unlockDate}
        setUnlockDate={setUnlockDate}
        isForever={isForever}
        setIsForever={setIsForever}
        {...props}
      >
        <Button w='100%'>Insufficient {token.symbol}</Button>
      </Wrapper>
    );

  if (allowance.isZero() || allowance.div(10 ** token?.decimals).isLessThan(new BigNumber(amount)))
    return (
      <Wrapper
        token={token}
        amount={new BigNumber(amount)}
        setAmount={setAmount}
        unlockDate={unlockDate}
        setUnlockDate={setUnlockDate}
        isForever={isForever}
        setIsForever={setIsForever}
        {...props}
      >
        <Button variant='primary' onClick={handleApprove} w='100%'>
          Approve {token?.symbol}
        </Button>
      </Wrapper>
    );

  return (
    <Wrapper
      token={token}
      amount={new BigNumber(amount)}
      setAmount={setAmount}
      unlockDate={unlockDate}
      setUnlockDate={setUnlockDate}
      isForever={isForever}
      setIsForever={setIsForever}
      {...props}
    >
      <ConfirmDialog
        isDisabled={!isForever}
        colorScheme='brand'
        confirmText='Confirm'
        title='Lock Forever'
        description='Are you sure you want to lock your deposit FOREVER?'
        onConfirm={() =>
          handleDeposit(
            token,
            new BigNumber(amount).times(10 ** token?.decimals).toFixed(),
            isForever ? FOREVER_TIMESTAMP_HEX : moment(unlockDate).unix()
          )
        }
        w='100%'
      >
        <Button variant='primary' w='100%'>
          Deposit
        </Button>
      </ConfirmDialog>
    </Wrapper>
  );
}

const Wrapper = ({
  children,
  token,
  amount,
  setAmount,
  unlockDate,
  setUnlockDate,
  isForever,
  setIsForever,
  ...props
}) => {
  const { calculateCarpeReward } = useGovernanceClaim();
  const [reward, setReward] = useState(BIG_ZERO);

  const calculateReward = useCallback(async () => {
    try {
      const res = await calculateCarpeReward(
        token?.address,
        amount.times(10 ** token?.decimals).toFixed(),
        moment(unlockDate)?.unix() - moment().unix()
      );
      setReward(new BigNumber(res).div(10 ** 18));
    } catch (err) {
      console.err(err);
    }
  }, [amount, calculateCarpeReward, token, unlockDate]);

  useEffect(() => {
    calculateReward();
  }, [calculateReward]);

  const color = useColorModeValue('gray.700', 'gray.300');

  return (
    <VStack spacing='5' w='100%' {...props}>
      <SimpleGrid columns={{ base: 2, sm: 4 }} w='100%' justifyContent='space-between' spacing='3'>
        <DateTimePicker
          selectedDate={unlockDate}
          onChange={setUnlockDate}
          popperPlacement='bottom-start'
        >
          <Button
            variant='outline'
            color={color}
            size='sm'
            onClick={() => setIsForever(true)}
            borderRadius='full'
            w='100%'
          >
            Set date
          </Button>
        </DateTimePicker>
        <TimeButton
          amount={1}
          unit='year'
          color={color}
          setUnlockDate={setUnlockDate}
          setIsForever={setIsForever}
        />
        <TimeButton
          amount={5}
          unit='years'
          color={color}
          setUnlockDate={setUnlockDate}
          setIsForever={setIsForever}
        />
        <Button
          variant='outline'
          color={color}
          size='sm'
          onClick={() => setIsForever(true)}
          borderRadius='full'
        >
          Forever
        </Button>
      </SimpleGrid>
      <Divider />
      <VStack
        spacing={{ base: '3', md: '1' }}
        w='100%'
        color={useColorModeValue('gray.700', 'gray.200')}
      >
        <Stack
          alignItems={{ base: 'flex-start', md: 'center' }}
          direction={{ base: 'column', md: 'row' }}
          w='100%'
          spacing='0'
          justifyContent='space-between'
        >
          {isForever ? <Text>Locked</Text> : <Text>Unlocks {moment(unlockDate).fromNow()}</Text>}
          {isForever ? (
            <HStack color='inherit' fontWeight='semibold' spacing='3' textTransform='uppercase'>
              <Text>Forever</Text>
              <Icon as={ImInfinite} color='brand.500' />
            </HStack>
          ) : (
            <HStack alignItems='center' spacing='4' cursor='pointer'>
              <Text fontWeight='semibold'>{moment(unlockDate)?.format('DD/MM/yyyy, HH:mm')}</Text>
              <Icon as={FiCalendar} color='brand.500' />
            </HStack>
          )}
        </Stack>
        <Stack
          alignItems={{ base: 'flex-start', md: 'center' }}
          direction={{ base: 'column', md: 'row' }}
          w='100%'
          spacing='0'
          justifyContent='space-between'
        >
          <Text>Rewards</Text>
          <Stack alignItems='center' direction={{ base: 'row-reverse', md: 'row' }} spacing='3'>
            <Text fontWeight='semibold'>{reward.toFixed(18)} CARPE</Text>
            <Image src={CarpeLogo} h='4' />
          </Stack>
        </Stack>
      </VStack>
      {children}
    </VStack>
  );
};

function TimeButton({ amount, unit, color, setUnlockDate, setIsForever }) {
  const onClick = () => {
    setIsForever(false);
    setUnlockDate(moment().add(amount, unit).toDate());
  };

  return (
    <Button size='sm' variant='outline' color={color} onClick={onClick} borderRadius='full'>
      <Text>
        {amount} {unit}
      </Text>
    </Button>
  );
}
