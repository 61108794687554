import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import qs from 'qs';
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { FiArrowDownCircle, FiMinus } from 'react-icons/fi';
import CurrencyInput from '../../components/CurrencyInput';
import TokenList from '../../utils/tokenlist';
import useCoingeckoPrice from '../../hooks/useCoingeckoPrice';
import { useDebouncedCallback } from 'use-debounce/lib';
import useCurrencyBalance from '../../hooks/useCurrencyBalance';
import { API_0X } from '../../utils/swap';
import useEagerConnect from '../../hooks/useEagerConnect';
import { useApprove } from '../../hooks/useApprove';
import { isValidETHAddress } from '../../utils/address-helper';
import useWeb3 from '../../hooks/useWeb3';
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback';

export default function Swap() {
  useEagerConnect();

  const slippagePercentage = 0.008;
  const { chainId, account } = useWeb3React();
  const [buyToken, setBuyToken] = useState();
  const [sellToken, setSellToken] = useState();
  const [buyAmount, setBuyAmount] = useState();
  const [sellAmount, setSellAmount] = useState();
  const [allowanceTarget, setAllowanceTarget] = useState();
  const [minimumReceived, setMinimumReceived] = useState();
  const [error, setError] = useState();

  const web3 = useWeb3();
  const [isLoading, setIsLoading] = useState(false);
  const [transaction, setTransaction] = useState();

  const ethPrice = useCoingeckoPrice(TokenList[1][0]);

  useEffect(() => {
    const tokenList = TokenList[chainId] || [];
    setSellToken(tokenList[0]);
    setBuyToken(tokenList[1]);
  }, [chainId]);

  const invertTokens = () => {
    const tmp = buyToken;
    const tmpAmount = buyAmount;
    setBuyToken(sellToken);
    setSellToken(tmp);
    setBuyAmount(sellAmount);
    setSellAmount(tmpAmount);
  };

  const buyTokenInUsd = useCoingeckoPrice(buyToken);
  const sellTokenInUsd = useCoingeckoPrice(sellToken);

  const sellTokenBalance = useCurrencyBalance(sellToken);

  const fetchQuote = useDebouncedCallback(async () => {
    const url = API_0X[chainId];

    setError(null);

    setBuyAmount('');

    if (!sellAmount || sellAmount <= 0 || !sellToken || !buyToken) return;

    if (
      (sellToken?.isNative || sellToken?.symbol === 'WETH') &&
      (buyToken?.isNative || buyToken?.symbol === 'WETH')
    ) {
      setBuyAmount(sellAmount);
      return;
    }

    if (!url) return;

    try {
      setIsLoading(true);

      const params = {
        sellToken: sellToken?.isNative ? 'ETH' : sellToken?.address,
        sellAmount: new BigNumber(sellAmount * 10 ** sellToken?.decimals).toFixed(),
        buyToken: buyToken?.address,
        slippagePercentage,
        takerAddress: account,
      };

      const res = await fetch(`${url}/swap/v1/quote?${qs.stringify(params)}`);

      const data = await res.json();

      if (data?.reason) {
        setError(data);
      } else {
        const { buyAmount: _buyAmount, guaranteedPrice, allowanceTarget } = data;

        setAllowanceTarget(allowanceTarget);
        setMinimumReceived(
          new BigNumber(guaranteedPrice)
            .times(sellAmount)
            .decimalPlaces(buyToken?.decimals)
            .toString()
        );
        setTransaction(data);
        setBuyAmount(new BigNumber(_buyAmount).div(10 ** buyToken?.decimals).toFixed());
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, 150);

  useEffect(() => {
    fetchQuote.call();
  }, [fetchQuote, buyToken, sellToken, sellAmount]);

  const handleSwap = useCallback(
    async tx => {
      try {
        await web3.eth.sendTransaction(tx);
      } catch (err) {
        console.error(err);
      }
    },
    [web3]
  );

  const openRampModal = useCallback(() => {
    new RampInstantSDK({
      hostAppName: 'Carpe DAO',
      hostLogoUrl: 'https://i.imgur.com/3ggdgL6.png',
      swapAsset: 'ETH',
      userAddress: account,
    }).show();
  }, [account]);

  const color = useColorModeValue('gray.600', 'gray.300');

  const hasSellBalance = formatBalance(sellToken, sellTokenBalance) > 0;

  const networkFee = new BigNumber(transaction?.gas)
    .times(transaction?.gasPrice)
    .div(10 ** 18)
    .times(ethPrice);

  return (
    <VStack w='100%' h='100%' py={{ base: '16', md: '32' }}>
      <VStack spacing='0' w='100%'>
        <VStack w='100%' spacing='4' maxW='520px' px='6'>
          <Box textAlign='center'>
            <Heading fontSize={{ base: '2xl', md: '4xl' }}>Swap</Heading>
            <Text
              fontSize={{ base: 'sm', md: 'md' }}
              color={useColorModeValue('gray.500', 'gray.200')}
            >
              Change your weak assets for strong assets
            </Text>
          </Box>
          <VStack
            w='100%'
            bg={useColorModeValue('gray.100', 'gray.800')}
            borderRadius='lg'
            p='6'
            spacing='1'
          >
            <CurrencyInput
              label='From'
              currency={sellToken}
              setCurrency={setSellToken}
              amount={sellAmount}
              setAmount={setSellAmount}
              amountInUsd={sellAmount * sellTokenInUsd}
              type='swap'
            />

            {hasSellBalance ? (
              <Text w='100%' color={color}>
                {formatBalance(sellToken, sellTokenBalance)} {sellToken?.symbol}
              </Text>
            ) : (
              <Text
                w='100%'
                color={color}
                cursor='pointer'
                onClick={openRampModal}
                _hover={{ textDecoration: 'underline' }}
              >
                Buy ETH
              </Text>
            )}

            <Box cursor='pointer' p='1' onClick={invertTokens}>
              <Icon as={FiArrowDownCircle} color='gray.300' />
            </Box>

            <CurrencyInput
              label='To (estimated)'
              currency={buyToken}
              setCurrency={setBuyToken}
              amount={buyAmount}
              setAmount={setBuyAmount}
              amountInUsd={buyAmount * buyTokenInUsd}
              type='swap'
              isDisabled
            />

            <VStack w='100%' spacing='2' py='4' fontSize='sm'>
              <HStack w='100%' justifyContent='space-between'>
                <Text>Minimum Received</Text>
                {minimumReceived ? (
                  <Text>
                    {minimumReceived} {buyToken?.symbol}
                  </Text>
                ) : (
                  <Icon as={FiMinus} />
                )}
              </HStack>
              <HStack w='100%' justifyContent='space-between'>
                <Text>Network Fee</Text>

                {networkFee > 0 ? <Text>${networkFee.toFixed(2)}</Text> : <Icon as={FiMinus} />}
              </HStack>
            </VStack>

            <SwapActions
              handleSwap={handleSwap}
              transaction={transaction}
              isLoading={isLoading}
              fetchQuote={fetchQuote}
              error={error}
              allowanceTarget={allowanceTarget}
              sellToken={sellToken}
              buyToken={buyToken}
              sellAmount={sellAmount}
            />
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
}

const formatBalance = (token, balance) => {
  return token?.decimals
    ? balance
        .div(10 ** token?.decimals)
        .decimalPlaces(token?.decimals)
        .toFixed()
    : 0;
};

function SwapActions({
  handleSwap,
  sellToken,
  buyToken,
  sellAmount,
  allowanceTarget,
  isLoading,
  fetchQuote,
  refreshIn,
  error,
  transaction,
}) {
  const { onApprove } = useApprove(
    isValidETHAddress(sellToken?.address) ? sellToken?.address : null,
    allowanceTarget
  );

  const handleApprove = useCallback(async () => {
    await onApprove();
    fetchQuote();
  }, [onApprove, fetchQuote]);

  const { wrapType, execute, inputError } = useWrapCallback(sellToken, buyToken, sellAmount);

  if (wrapType === WrapType.WRAP) {
    return (
      <Button w='100%' variant='primary' onClick={execute}>
        {inputError || 'Wrap'}
      </Button>
    );
  }

  if (wrapType === WrapType.UNWRAP) {
    return (
      <Button w='100%' variant='primary' onClick={execute}>
        {inputError || 'Unwrap'}
      </Button>
    );
  }

  if (error) {
    switch (error.code) {
      case 105:
        return (
          <Button w='100%' variant='primary' onClick={handleApprove}>
            Approve
          </Button>
        );
      default:
        return (
          <Button w='100%' onClick={fetchQuote}>
            {error.reason}
          </Button>
        );
    }
  }

  if (refreshIn <= 0)
    return (
      <Button onClick={fetchQuote} isLoading={isLoading} w='100%'>
        Refresh Swap Rates
      </Button>
    );

  return (
    <Button
      w='100%'
      variant='primary'
      onClick={() => handleSwap(transaction)}
      isLoading={isLoading}
    >
      Swap
    </Button>
  );
}
