import CarpeSVG from '../assets/branding/carpe.svg';
import CarpeWhiteSVG from '../assets/branding/carpe-white.svg';
import Carpe from '../assets/branding/carpe.png';
import Vault from '../assets/vaults/vault.png';
import USDT from '../assets/crypto/USDT.svg';
import ETH from '../assets/crypto/ETH.svg';
import BTC from '../assets/crypto/BTC.svg';
import AAVE from '../assets/crypto/aave.png';
import COMP from '../assets/crypto/comp.png';
import CRV from '../assets/crypto/crv.png';
import DAI from '../assets/crypto/dai.png';
import MKR from '../assets/crypto/mkr.png';
import UNI from '../assets/crypto/uni.png';
import USDC from '../assets/crypto/usdc.png';
import WETH from '../assets/crypto/weth.png';
import { ChainId } from '../config/constants/networks';

export const CarpeLogo = CarpeSVG;
export const CarpeWhiteLogo = CarpeWhiteSVG;
export const CarpeImage = Carpe;
export const VaultImage = Vault;

export const BTCImage = BTC;
export const ETHImage = ETH;
export const USDTImage = USDT;
export const AAVEImage = AAVE;
export const COMPImage = COMP;
export const CRVImage = CRV;
export const DAIImage = DAI;
export const MKRImage = MKR;
export const UNIImage = UNI;
export const USDCImage = USDC;
export const WETHImage = WETH;

export const FOREVER_TIMESTAMP =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935';
export const FOREVER_TIMESTAMP_HEX =
  '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF';

export const DefaultChainId =
  process.env.REACT_APP_NODE_ENV === 'production' ? ChainId.MAINNET : ChainId.RINKEBY;

export const FEE_PRICE = 0.03;
