import { ChainId } from "../config/constants/networks";

// https://0x.org/docs/api#swap

export const API_0X = {
  [ChainId.MAINNET]: "https://api.0x.org",
  [ChainId.BSC]: "https://bsc.api.0x.org",
  [ChainId.ROPSTEN]: "https://ropsten.api.0x.org/",
};

export const API_COINGECKO = "https://api.coingecko.com/api/v3";
