import MersenneTwister from 'mersenne-twister';
import jsNumberForAddress from './jsNumberForAddress';
import colors from './colors';
import Paper from './Paper';

const shapeCount = 4;
const svgns = 'http://www.w3.org/2000/svg';
const wobble = 30;

export default function Jazzicon({ diameter, paperStyles, address, seed, svgStyles }) {
  if (!address && !seed) return <></>;

  const generator = new MersenneTwister(seed || jsNumberForAddress(address));

  const genColor = colors => {
    generator.random();
    const idx = Math.floor(colors.length * generator.random());
    return colors.splice(idx, 1)[0];
  };

  function hueShift(colors, generator) {
    const amount = generator.random() * 30 - wobble / 2;
    const rotate = hex => colorRotate(hex, amount);
    return colors.map(rotate);
  }

  function colorRotate(hex, degrees) {
    let hsl = hexToHSL(hex);
    let hue = hsl.h;
    hue = (hue + degrees) % 360;
    hue = hue < 0 ? 360 + hue : hue;
    hsl.h = hue;
    return HSLToHex(hsl);
  }

  function hexToHSL(hex) {
    // Convert hex to RGB first
    let r = '0x' + hex[1] + hex[2];
    let g = '0x' + hex[3] + hex[4];
    let b = '0x' + hex[5] + hex[6];
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

    if (delta === 0) h = 0;
    else if (cmax === r) h = ((g - b) / delta) % 6;
    else if (cmax === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return { h, s, l };
  }

  function HSLToHex(hsl) {
    let { h, s, l } = hsl;
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
      m = l - c / 2,
      r = 0,
      g = 0,
      b = 0;

    if (0 <= h && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (60 <= h && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (120 <= h && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (180 <= h && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (240 <= h && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else if (300 <= h && h < 360) {
      r = c;
      g = 0;
      b = x;
    }
    // Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;

    return '#' + r + g + b;
  }

  const genShape = (remainingColors, diameter, i, total) => {
    const center = diameter / 2 || 0;
    const firstRot = generator.random();
    const angle = Math.PI * 2 * firstRot;
    const velocity = (diameter / total) * generator.random() + (i * diameter) / total;
    const tx = Math.cos(angle) * velocity || 0;
    const ty = Math.sin(angle) * velocity || 0;
    const translate = 'translate(' + tx + ' ' + ty + ')';

    // Third random is a shape rotation on top of all of that.
    const secondRot = generator.random();
    const rot = firstRot * 360 + secondRot * 180;
    const rotate = 'rotate(' + rot.toFixed(1) + ' ' + center + ' ' + center + ')';
    const transform = translate + ' ' + rotate;
    const fill = genColor(remainingColors);

    return (
      <rect
        key={i}
        x='0'
        y='0'
        rx='0'
        ry='0'
        height={diameter}
        width={diameter}
        transform={transform}
        fill={fill} // todo: make prop
      />
    );
  };

  const remainingColors = hueShift(colors.slice(), generator);
  const shapesArr = Array(shapeCount).fill();

  return (
    <Paper color={genColor(remainingColors)} diameter={diameter} style={paperStyles}>
      <svg xmlns={svgns} x='0' y='0' height={diameter} width={diameter} style={svgStyles}>
        {shapesArr.map((s, i) => genShape(remainingColors, diameter, i, shapeCount - 1))}
      </svg>
    </Paper>
  );
}
