import { ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Text } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { NetworkLabel } from '../../config/constants/networks';
import useCurrencyBalance from '../../hooks/useCurrencyBalance';
import { DefaultChainId } from '../../utils/consts';
import CurrencyList from './CurrencyList';

export default function CurrencySearch({
  currencies,
  selectedCurrency,
  otherCurrency,
  onClose,
  onCurrencySelect,
}) {
  const { chainId } = useWeb3React();

  return (
    <ModalContent w='100%' maxW={{ base: 'calc(100% - 64px)', md: 'lg' }} borderRadius='lg'>
      <ModalCloseButton />
      <ModalHeader>Select a token</ModalHeader>
      <CurrencyList
        currencies={currencies}
        selectedCurrency={selectedCurrency}
        otherCurrency={otherCurrency}
        onCurrencySelect={onCurrencySelect}
      />
      <ModalFooter borderBottomRadius='lg'>{NetworkLabel[chainId || DefaultChainId]}</ModalFooter>
    </ModalContent>
  );
}

export function CurrencyBalance({ currency, ...rest }) {
  const balance = useCurrencyBalance(currency);
  const formattedBalance = balance.div(10 ** currency?.decimals).toFixed();

  return <Text {...rest}>{formattedBalance}</Text>;
}
