import { useEffect, useState } from 'react';
import { API_COINGECKO } from '../utils/swap';

export default function useCoingeckoPrice(currency) {
  const [price, setPrice] = useState(0);
  const geckoChainId = 'ethereum';

  useEffect(() => {
    const fetchPrice = async () => {
      const res = await fetch(
        `${API_COINGECKO}/simple/price?ids=${currency?.coingeckoId}&vs_currencies=usd`
      );

      const data = await res.json();
      setPrice(data?.[currency?.coingeckoId]?.usd || 0);
    };

    fetchPrice();
  }, [currency?.coingeckoId, geckoChainId]);

  return price;
}
