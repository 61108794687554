import React from 'react';
import { Switch } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { MainLayout } from './layouts';
import { Deposits, HODLVaults, Portfolio, Swap } from './views';

export default function Routes() {
  return (
    <Switch>
      <RouteWithLayout layout={MainLayout} component={Portfolio} exact path='/' />
      <RouteWithLayout
        layout={MainLayout}
        component={HODLVaults}
        exact
        path={['/hodl', '/hodl/:token']}
      />
      <RouteWithLayout layout={MainLayout} component={Swap} exact path='/swap' />
      <RouteWithLayout layout={MainLayout} component={Deposits} exact path='/deposits/:depositId' />
      {/* <RouteWithLayout layout={MainLayout} component={YieldVaults} exact path='/yield-vaults' /> */}
    </Switch>
  );
}
