import React, { useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  Button,
  Center,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import useEagerConnect from '../../hooks/useEagerConnect';
import { useWeb3React } from '@web3-react/core';
import { getUserDeposits } from '../../utils/HODLVaults-utils';
import { useHODLVaults } from '../../hooks/useContract';
import LoadingCarpe from '../../components/LoadingCarpe';
import TokenList from '../../utils/tokenlist';
import { Link } from 'react-router-dom';
import { BsQuestion } from 'react-icons/bs';
import DepositCard from '../Deposits/DepositCard';
import { DefaultChainId } from '../../utils/consts';

export default function Portfolio() {
  useEagerConnect();

  const { account } = useWeb3React();
  const HODLVaultsContract = useHODLVaults();
  const [deposits, setDeposits] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserData = useCallback(async () => {
    if (account) {
      setIsLoading(true);
      const res = await getUserDeposits(HODLVaultsContract, account);
      if (res) setDeposits(res);
    }
    setIsLoading(false);
  }, [HODLVaultsContract, account]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <Container
      maxW={{ base: 'sm', sm: 'container.xs', md: 'container.sm', lg: 'container.lg' }}
      justifyContent='center'
      h='100%'
      w='100%'
    >
      {isLoading ? (
        <Center w='100%' h='100%'>
          <LoadingCarpe />
        </Center>
      ) : (
        <Deposits deposits={deposits} />
      )}
    </Container>
  );
}

function Deposits({ deposits }) {
  const { chainId } = useWeb3React();
  const tokens = TokenList[1].filter(t => !t.isNative);

  if (!deposits || deposits.length === 0)
    return (
      <Center maxW='container.lg' h='100%'>
        <VStack spacing='8' cursor='pointer'>
          <VStack spacing='4'>
            <Heading size='lg'>HODL your crypto with NFTs</Heading>
            <AvatarGroup>
              {tokens.map(token => (
                <Avatar
                  key={token.symbol}
                  src={token.logoURI}
                  boxSize={{ base: '9', md: '14' }}
                  icon={<Icon as={BsQuestion} />}
                  transition='all 0.2s'
                  _hover={{ transform: 'scale(1.1)', zIndex: 1 }}
                  cursor='pointer'
                  as={Link}
                  to={`/hodl/${token.symbol}`}
                />
              ))}
            </AvatarGroup>
          </VStack>
          <Button as={Link} to='/hodl' variant='primary'>
            HODL NOW
          </Button>
        </VStack>
      </Center>
    );

  return (
    <SimpleGrid
      overflow='auto'
      spacing='4'
      w='100%'
      py='10'
      columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
    >
      {deposits
        .sort((d1, d2) => d1.withdrawTimestamp - d2.withdrawTimestamp)
        .map(deposit => (
          <Link key={deposit.index} to={`/deposits/${deposit.index}`}>
            <DepositCard
              key={deposit.index}
              index={deposit.index}
              amount={deposit.amount}
              token={TokenList[chainId || DefaultChainId].find(
                t => t.address?.toLowerCase() === deposit.token?.toLowerCase()
              )}
              tokenAddress={deposit.token}
              withdrawTimestamp={deposit.withdrawTimestamp}
            />
          </Link>
        ))}
    </SimpleGrid>
  );
}
