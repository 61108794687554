import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import ThemeProvider from './theme/ThemeProvider';
import Routes from './Routes';
import { getLibrary } from './utils/connectors';
import { RefreshContextProvider } from './contexts/RefreshContext';

function App() {
  return (
    <Router>
      <ThemeProvider>
        <RefreshContextProvider>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Routes />
          </Web3ReactProvider>
        </RefreshContextProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
