import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useWeb3React } from "@web3-react/core";
import { BIG_INFINITY, BIG_ZERO } from "../utils/big-number";
import { useERC20 } from "./useContract";
import useRefresh from "./useRefresh";

export const useAllowance = (tokenAddress, targetAddress) => {
  const [allowance, setAllowance] = useState(BIG_ZERO);
  const { account, chainId } = useWeb3React();
  const erc20 = useERC20(tokenAddress);
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const fetchAllowance = async () => {
      try {
        if (erc20) {
          const res = await erc20.methods
            .allowance(account, targetAddress)
            .call();
          setAllowance(new BigNumber(res));
        } else {
          setAllowance(BIG_INFINITY);
        }
      } catch (e) {}
    };

    if (account) {
      fetchAllowance();
    }
  }, [account, erc20, fastRefresh, chainId, targetAddress]);

  return allowance;
};
