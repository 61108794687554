import React, { useEffect, useState } from 'react';
import {
  HStack,
  VStack,
  Text,
  NumberInput,
  NumberInputField,
  useColorModeValue,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  Button,
} from '@chakra-ui/react';
import { BIG_ZERO } from '../../utils/big-number';
import useCoingeckoPrice from '../../hooks/useCoingeckoPrice';
import useCurrencyBalance from '../../hooks/useCurrencyBalance';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';

export default function IncreaseDeposit({ depositIdx, handleIncreaseDeposit, token }) {
  const { account } = useWeb3React();
  const balance = useCurrencyBalance(token);
  const price = useCoingeckoPrice(token);
  const [addAmount, setAddAmount] = useState(BIG_ZERO);
  const [amountPercentage, setAmountPercentage] = useState(0);

  useEffect(() => {
    setAddAmount(balance.div(10 ** token.decimals).times(amountPercentage / 100));
  }, [amountPercentage, balance, token.decimals]);

  const textColor = useColorModeValue('gray.500', 'gray.300');

  if (!account) return <></>;

  return (
    <VStack w='100%' spacing='4' py='' alignItems='flex-start'>
      <VStack w='100%' alignItems='flex-start' spacing='0'>
        <HStack w='100%' justifyContent='space-between'>
          <Text color={textColor}>Increase Deposit</Text>
          <Text fontSize={{ base: 'md', md: 'lg' }}>≈$ {(addAmount * price).toFixed(2)}</Text>
        </HStack>
        <CurrencyInput currency={token} amount={addAmount} setAmount={setAddAmount} />
      </VStack>
      <VStack w='100%' spacing='0'>
        <Slider
          aria-label='slider'
          min={0}
          max={100}
          defaultValue={0}
          onChange={e => setAmountPercentage(e)}
        >
          <SliderTrack>
            <SliderFilledTrack bg='brand.500' />
          </SliderTrack>
          <Tooltip label={amountPercentage} placement='top'>
            <SliderThumb />
          </Tooltip>
        </Slider>
        <HStack w='100%' justifyContent='space-between' fontSize='sm' color={textColor}>
          <Text>0%</Text>
          <Text>25%</Text>
          <Text>50%</Text>
          <Text>75%</Text>
          <Text>100%</Text>
        </HStack>
      </VStack>
      <Button
        w='100%'
        variant='primary'
        onClick={() =>
          handleIncreaseDeposit(
            depositIdx,
            new BigNumber(addAmount).times(10 ** token?.decimals).toFixed()
          )
        }
        size='sm'
      >
        Deposit
      </Button>
    </VStack>
  );
}

function CurrencyInput({
  currency,
  label,
  setCurrency,
  amount,
  setAmount,
  amountInUsd,
  isDisabled,
  ...rest
}) {
  return (
    <VStack
      alignItems='start'
      w='100%'
      border='1px'
      borderColor={useColorModeValue('gray.200', 'gray.600')}
      borderRadius='lg'
      px='3'
      spacing='0'
      bg={useColorModeValue('gray.50', 'gray.700')}
      _hover={{ bg: useColorModeValue('gray.100', 'gray.800') }}
      _focusWithin={{
        bg: useColorModeValue('gray.100', 'gray.800'),
        borderColor: useColorModeValue('blue.400', 'blue.600'),
      }}
      {...rest}
    >
      <HStack
        w='100%'
        justifyContent={{ base: 'flex-end', md: 'flex-start' }}
        color={useColorModeValue('gray.500', 'gray.300')}
        fontSize='lg'
        _focusWithin={{ color: useColorModeValue('gray.400', 'brand.600') }}
      >
        <Text fontSize={{ base: 'xs', md: 'sm' }}>{label}</Text>
        {amountInUsd && <Text fontSize='sm'>≈$ {amountInUsd.toFixed(2)}</Text>}
      </HStack>

      <NumberInput
        value={amount || ''}
        onChange={value => setAmount(value)}
        isDisabled={isDisabled}
        size='lg'
        variant='unstyled'
        w='100%'
      >
        <NumberInputField
          fontSize='xl'
          placeholder='0.00'
          textAlign={{ base: 'start', md: 'end' }}
          p='0'
          _placeholder={{ color: useColorModeValue('gray.600', 'gray.300') }}
        />
      </NumberInput>
    </VStack>
  );
}
