const Paper = ({ children, color, diameter, style: styleOverrides }) => (
  <div
    style={{
      borderRadius: 50,
      display: 'inline-block',
      margin: 0,
      overflow: 'hidden',
      padding: 0,
      backgroundColor: color,
      height: diameter,
      width: diameter,
      ...styleOverrides,
    }}
  >
    {children}
  </div>
);

export default Paper;
