import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const config = {
  useSystemColorMode: false,
  initialColorMode: 'dark',
};

const fonts = {
  body: 'Rajdhani, system-ui, sans-serif',
  heading: 'Rajdhani, system-ui, sans-serif',
};

const components = {
  Button: {
    baseStyle: {
      borderRadius: 'md',
    },
    variants: {
      primary: {
        bg: 'brand.500',
        _hover: { bg: 'brand.600' },
        color: 'white',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      secondary: props => ({
        color: mode('gray.900', 'white')(props),
        border: '1px solid',
        borderColor: mode('gray.900', 'white')(props),
        fontWeight: 'medium',
        _hover: {
          bg: '#ffffff10',
        },
      }),
    },
  },
};

const styles = {
  global: props => ({
    'html, body': {
      bg: mode('gray.50', 'gray.900')(props),
      color: mode('#000000', '#ffffff')(props),
      height: '100%',
    },
    footer: {
      bg: mode('gray.100', 'gray.800')(props),
    },
  }),
};

const colors = {
  brand: {
    50: '#ffffff',
    100: '#fde1d7',
    200: '#fbc3ae',
    300: '#f8a686',
    400: '#f6885d',
    500: '#F46A35',
    600: '#c3552a',
    700: '#924020',
    800: '#622a15',
    900: '#31150b',
  },
  gray: {
    50: '#ffffff',
    100: '#eeeeee',
    200: '#dfdfdf',
    300: '#b1b1b1',
    400: '#999999',
    500: '#666666',
    600: '#333333',
    700: '#212121',
    800: '#141414',
    900: '#000000',
  },
};

export default extendTheme({ config, fonts, colors, styles, components });
