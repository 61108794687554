import { useTheme } from '@chakra-ui/react';

export default function useBgGradient(props) {
  const theme = useTheme();
  const primary = theme.colors.brand[500];

  const direction = props?.direction || 'to-t';
  const opacityHex = props?.opacityHex || '45';
  const endingPercentage = props?.endingPercentage || '50%';

  return `linear(${direction}, ${primary}${opacityHex} 0%, ${primary}00 ${endingPercentage})`;
}
