import React, { memo } from 'react';
import {
  HStack,
  VStack,
  Text,
  NumberInput,
  NumberInputField,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react';
import CurrencySelect from './CurrencySelect';

function CurrencyInput({
  currency,
  label,
  setCurrency,
  amount,
  setAmount,
  amountInUsd,
  isDisabled,
  type,
  ...rest
}) {
  return (
    <VStack
      alignItems='start'
      w='100%'
      border='1px'
      borderColor={useColorModeValue('gray.200', 'gray.600')}
      borderRadius='lg'
      py='1'
      px='3'
      spacing='0'
      bg={useColorModeValue('gray.50', 'gray.700')}
      _hover={{ bg: useColorModeValue('gray.100', 'gray.800') }}
      _focusWithin={{
        bg: useColorModeValue('gray.100', 'gray.800'),
        borderColor: useColorModeValue('brand.400', 'brand.600'),
      }}
      {...rest}
    >
      <HStack
        w='100%'
        justifyContent={{ base: 'flex-end', md: 'flex-start' }}
        color={useColorModeValue('gray.500', 'gray.300')}
        fontSize='lg'
        _focusWithin={{ color: useColorModeValue('gray.400', 'brand.600') }}
      >
        <Text fontSize={{ base: 'xs', md: 'sm' }}>{label}</Text>
        {amountInUsd && <Text fontSize='sm'>≈$ {amountInUsd.toFixed(2)}</Text>}
      </HStack>

      <Stack direction={{ base: 'column', md: 'row' }} w='100%' justifyContent='space-between'>
        <CurrencySelect
          currency={currency}
          setCurrency={setCurrency}
          alignSelf={{ base: 'flex-end', md: 'flex-start' }}
          type={type}
        />
        <NumberInput
          value={amount || ''}
          onChange={value => setAmount(value)}
          isDisabled={isDisabled}
          size='lg'
          variant='unstyled'
          w='100%'
        >
          <NumberInputField
            fontSize='xl'
            placeholder='0.00'
            textAlign={{ base: 'start', md: 'end' }}
            p='0'
            _placeholder={{ color: useColorModeValue('gray.600', 'gray.300') }}
          />
        </NumberInput>
      </Stack>
    </VStack>
  );
}

export default memo(CurrencyInput);
