import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { isValidETHAddress } from '../../utils/address-helper';

export default function TransferModalDeposit({ depositIdx, handleTransfer, children, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [toAddress, setToAddress] = useState('');
  const toast = useToast();

  const onSubmit = useCallback(async () => {
    if (isValidETHAddress(toAddress)) {
      await handleTransfer(toAddress, depositIdx);
      onClose();
    } else {
      toast({
        title: 'Invalid address',
        description: 'Please use a valid ETH address',
        status: 'warning',
      });
    }
  }, [depositIdx, handleTransfer, onClose, toAddress, toast]);

  const isInvalid = !isValidETHAddress(toAddress);

  return (
    <>
      <Box onClick={onOpen} {...props}>
        {children}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Transfer Deposit</ModalHeader>
          <ModalBody>
            <VStack w='100%'>
              <FormControl>
                <FormLabel>To Address</FormLabel>
                <Input
                  w='100%'
                  value={toAddress}
                  onChange={e => setToAddress(e.target.value)}
                  placeholder='0xaddress'
                  isInvalid={isInvalid}
                />
                {isInvalid && (
                  <FormHelperText color='red'>Please use a valid ETH address</FormHelperText>
                )}
              </FormControl>
              <Button
                variant='primary'
                alignSelf='flex-end'
                px='12'
                onClick={onSubmit}
                isDisabled={isInvalid}
              >
                Send
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
