import React, { useCallback, useEffect, useState } from 'react';
import { Container, Stack, useToast } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { useHODLVaults as useHODLVaultsContract } from '../../hooks/useContract';
import useEagerConnect from '../../hooks/useEagerConnect';
import { getDeposit } from '../../utils/HODLVaults-utils';
import { useHistory, useParams } from 'react-router-dom';
import DepositCard from './DepositCard';
import DepositInfo from './DepositInfo';
import TokenList from '../../utils/tokenlist';
import { getErc20Contract } from '../../utils/contract-helper';
import useWeb3 from '../../hooks/useWeb3';
import { DefaultChainId } from '../../utils/consts';

export default function Deposits() {
  useEagerConnect();

  const history = useHistory();

  const web3 = useWeb3();
  const { chainId } = useWeb3React();
  const toast = useToast();

  const { depositId } = useParams();

  const HODLVaultsContract = useHODLVaultsContract();

  const [deposit, setDeposit] = useState();
  const [token, setToken] = useState();

  const getTokenInfo = useCallback(
    async tokenAddress => {
      if (tokenAddress === '0x0000000000000000000000000000000000000000') {
        toast({
          title: 'Deposit has been withdrawn',
          status: 'warning',
        });
        history.push('/');
        return;
      }
      
      try {
        const foundToken = TokenList[chainId || DefaultChainId]?.find(
          t => t.address?.toLowerCase() === tokenAddress?.toLowerCase()
        );

        if (foundToken) {
          setToken(foundToken);
        } else {
          const tokenContract = getErc20Contract(tokenAddress, web3);
          const symbol = await tokenContract.methods.symbol().call();
          const decimals = await tokenContract.methods.decimals().call();
          const name = await tokenContract.methods.name().call();

          setToken({
            symbol,
            decimals: parseInt(decimals),
            name,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    [history, toast, web3, chainId]
  );

  const fetchDepositData = useCallback(async () => {
    const res = await getDeposit(HODLVaultsContract, depositId);

    if (res) {
      setDeposit(res);
      await getTokenInfo(res.token);
    }
  }, [depositId, HODLVaultsContract, getTokenInfo]);

  useEffect(() => {
    fetchDepositData();
  }, [fetchDepositData]);

  if (!deposit || !token) return <></>;

  return (
    <Container
      maxW={{ base: 'sm', sm: 'container.xs', md: 'container.sm', lg: 'container.lg' }}
      justifyContent='center'
      alignItems='center'
      h='100%'
      w='100%'
    >
      <Stack
        direction={{ base: 'column-reverse', lg: 'row' }}
        py='6'
        spacing='6'
        alignItems={{ base: 'center', lg: 'flex-start' }}
        justifyContent='space-between'
      >
        <DepositCard
          index={depositId}
          amount={deposit.amount}
          tokenAddress={deposit.token}
          token={token}
          withdrawTimestamp={deposit.withdrawTimestamp}
          minW='260px'
        />
        <DepositInfo
          fetchDepositData={fetchDepositData}
          index={depositId}
          depositToken={token}
          {...deposit}
        />
      </Stack>
    </Container>
  );
}
